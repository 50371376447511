<template>
    <div class="flex flex-col navbar">
        <div class="nav-cross">
            <div>
                <i @click="$emit('navClosed')" class="pi pi-times py-2.5 px-3" style="font-size: 1.2rem"></i>
            </div>
        </div>

        <div class="w-full text-left">
            <router-link :to="{ path: '/'}" >
                <p class="options"> Home </p>
            </router-link>

            <router-link to="/who-are-we">
                <p class="options">Who Are We</p>
            </router-link>

            <div class="flex w-full justify-between">
                <div class="w-2/3">
                    <router-link to="/area-of-work">
                        <p class="options w-full">Area Of Work</p>
                    </router-link>
                </div>
                <div class="flex items-center justify-end w-1/3 pr-4" @click="showDropdown">
                    <i v-if="toggleIcon" class="pi pi-angle-up p-1" style="font-size: 1.7rem"></i>
                    <i v-else class="pi pi-angle-down p-1" style="font-size: 1.7rem"></i>
                </div>
            </div>
            <div v-if="displayDropdown">
                    <div class="w-full flex flex-col text-left px-8">
                        <div class="w-full">
                            <div v-for="(item,index) in area" :key="index">
                                <router-link :to="{name:'areaofWorkDetails',params:{id:item.slug}}"><p class="text-base hover:text-primary py-1">{{item.title}}</p></router-link>
                            </div>
                        </div>
                    </div>
                </div>

            <router-link to="/research-and-publications">
                <p class="options">Research & Publications</p>
            </router-link>

            <router-link to="/ongoing-projects">
                <p class="options">Ongoing Projects</p>
            </router-link>

            <router-link to="/working-areas">
                <p class="options">Working Areas</p>
            </router-link>

            <router-link to="/gallery">
                <p class="options">Gallery</p>
            </router-link>

            <router-link to="/donate">
                <p class="options">Donors</p>
            </router-link>         
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return{
            displayDropdown: false,
            toggleIcon: false,
        }
    },

    methods: {
        showDropdown() {
            this.toggleIcon = !this.toggleIcon
            this.displayDropdown = !this.displayDropdown
        },
    },

    computed:{
        ...mapState({
            area: state => state.areaOfWorkData.areaOfWork
        })
    },

    mounted(){
        this.$store.dispatch('areaOfWorkData/getAreaOfWork')
    }
}
</script>

<style scoped>
.navbar {
    z-index: 999;
    width: 90vw;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @apply shadow-lg bg-pink-100;
}

.nav-cross { 
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-start;
    padding-top: 2%;
    padding-right: 4%;
    margin-bottom: 4%;
    @apply border-b border-pink-200;
}

.options {
    @apply px-4 my-2 py-1 uppercase text-base;
}
</style>