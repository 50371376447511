import person1 from '../../assets/employee/p-1.jpg'
import person2 from '../../assets/employee/p-2.jpg'
import person3 from '../../assets/employee/p-3.jpg'
import person4 from '../../assets/employee/p-4.jpg'
import person5 from '../../assets/employee/p-5.jpg'
import person6 from '../../assets/employee/p-6.jpg'
import person7 from '../../assets/employee/p-7.png'
import person8 from '../../assets/employee/p-8.jpg'
import person9 from '../../assets/employee/p-9.png'
const state={
    teams:[
        {
            id:1,
            image: person1,
            name:"Prof. Ishrat Shamim",
            designation:'President', 
        },
        {
            id:2,
            image: person2,
            name:"Prof. Taskin Fatema Moona",
            designation:'Vice- President',
        },
        {
            id:3,
            image: person3,
            name:"Shireen Hasan",
            designation:'Executive Director',
        },
        {
            id:4,
            image: person4,
            name:"Prof. Nusrat Sultana",
            designation:'Treasurer',
        },
        {
            id:5,
            image: person5,
            name:"Advocate Faria Sabrina Rashid",
            designation:'Executive Member', 
        },
        {
            id:6,
            image: person6,
            name:"Advocate Farida Yeasmin",
            designation:'Executive Member',
        },
        {
            id:7,
            image: person7,
            name:"Dr. Mumita Tanjeela",
            designation:'Executive Member',
        },
        {
            id:8,
            image: person8,
            name:"Kishwar Khan",
            designation:'Executive Member',
        },
        {
            id:9,
            image: person9,
            name:"Rita Bhowmick",
            designation:'Executive Member',
        },
    ],
            title:'Innovation Integrity,Individuality define us Impeccably.',
            aboutDesc1:'Centre for Women and Children Studies (CWCS) is a non-profit and non-political organization for encouraging, sponsoring and promoting multi-disciplinary basic and action research aimed at burgeoning socio-economic development of women, men and children. Centre was established in April 1994 to develop pro-women policy planning in Bangladesh and its implementation in a more meaningful way. It primarily acts as a pressure group to lobby with the policy planners and decision-makers. The vision of the Centre is the inclusion of women in policy agenda to ensure equality in socio-economic, legal and political spheres leading to distributive justice.',
            aboutDesc2:'CWCS has been registered in Bureau of NGO Affairs, Government of the Peoples’ Republic of Bangladesh since 1997. The registration number is 1116.',
}
const getters={}
const mutations={}
const actions={}
export default{
    namespaced:true,
    state,
    getters,mutations,actions
}