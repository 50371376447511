var host="https://cwcsapi.smicee.com"

const V1API = {
    // home api 
    'get_home_data': host+'/home/view/',
    'get_banner_data': host+'/home/slider-data',

    // are of work api 
    'get_area_of_work': host+'/areaofwork/list/',
    'get_area_of_work_details': host+'/projects/byaow/',
    'get_AOW_title': host+'/areaofwork/detail/', 

    // project api 
    'get_projects': host+'/projects/list/',
    'get_project_title': host+'/projects/details/',
    'get_ongoing_Project': host+'/projects/featured/',

    // gallery api
    'get_gallery': host+'/gallery/list/',
    'get_gallery_by_project': host + '/gallery/byProject/',

    // research api 
    'get_research': host + '/publications/list/',
    
    // who are we api
    'get_aboutUs':host+'/aboutus/list/',

    // contact api 
    'send_message': host+'/contact/create/'
}
export default V1API;