<template>
  <div class="flex flex-col w-full items-center my-12 lg:mb-28">
    <div class="container sm:px-8 lg:px-12 xl:px-16">
      <div class="flex w-full items-start justify-start">
          <p class="text-xl lg:text-4xl text-left font-medium pl-5 border-l-4 border-primary mb-5 mx-4 xl:mx-0 animate-charcter">Gallery</p>
      </div>
      <Galleria
        :value="galleryData"
        :activeIndex.sync="activeIndex"
        :numVisible="1"
        :responsiveOptions="responsiveOptions"
        :circular="true"
        :fullScreen="true"
        :showItemNavigators="true"
        containerStyle="max-width: 850px"
        :showThumbnails="false"
        :visible.sync="displayCustom"
      >
        <template #item="slotProps">
          <img :src="host + slotProps.item.image" alt="" />
        </template>
        <template #thumbnail="slotProps">
          <img
            :src="slotProps.item.thumbnailImageSrc"
            :alt="slotProps.item.alt"
            style="display: block"
          />
        </template>
      </Galleria>
      <div
        v-if="galleryData"
        class="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mx-4 xl:mx-0"
      >
        <div v-for="(items, index) of galleryData.slice(0,6)" class="bg-white rounded-lg col-3" :key="index">
          <img
            :src="host + items.image"
            style="cursor: pointer"
            class="w-full h-52 sm:h-56 xl:h-60 2xl:h-72 rounded-t-lg"
            @click="imageClick(index)"
          />
          <div class="text-justify p-3">
            <p class="text-lg lg:text-xl mb-2">{{ items.title }}</p>
            <p class="text-sm lg:text-base text-gray-600">{{ items.details }}</p>
          </div>
        </div>
      </div>
      <div class="mt-2 lg:mt-6">
        <button @click="toGallary" class="mt-4 lg:mt-6 buttonStyle border-primary hover:bg-primary">See More</button>
    </div>
    </div>
  </div>
</template>
<script>
import Galleria from "primevue/galleria";
import { mapState } from "vuex";

export default {
  data() {
    return {
      activeIndex: 0,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      displayCustom: false,
      host: "https://cmsapi.smicee.com",
    };
  },

  components: {
    Galleria,
  },

  computed: {
    ...mapState({
      galleryData: (state) => state.galleryData.galleryData,
    }),
  },
  mounted() {
    this.$store.dispatch("galleryData/getGallery");
  },
  methods: {
    toGallary() {
      this.$router.push("/gallery");
    },
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
  },
};
</script>
<style scoped>
.buttonStyle {
  @apply py-3 px-12 border-2 hover:text-white rounded-lg font-semibold;
  transition: background 300ms ease-in-out;
}
</style>
