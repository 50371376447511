<template>
	<div class="w-full">
		<!-- Footer Style Mobile -->
		<div class="footerStyleMobile flex lg:hidden" :style="{backgroundImage:`url(${host + homeData})`}">
			<div class="bg-color w-full">
				<div class="flex flex-col items-center px-4 py-8">
					<div class="text-center">
						<div class="text-white">
							<div class="flex flex-col items-center">
								<router-link to="/">
									<img :src="logo" class="w-24"/>
								</router-link>
							</div>
							<div class="flex justify-center gap-5 pt-5">
								<a target="_blank" href="https://www.facebook.com/people/Centre-for-Women-and-Children-Studies-CWCS/100083564160799/" class="navigation">
									<i class="pi pi-facebook" style="font-size: 1.2rem"></i>
								</a>

								<a target="_blank" href="https://www.linkedin.com/in/cwcs/" class="navigation">
									<i class="pi pi-linkedin" style="font-size: 1.2rem"></i>
								</a>

								<a target="_blank" href="https://twitter.com/CWCS_BD?fbclid=IwAR0-9xvXSEeT5fN0vLI7C43n5ZVd60gofCCadlkHqJywMCBIL_qeeUr4dxI" class="navigation">
									<i class="pi pi-twitter" style="font-size: 1.2rem"></i>
								</a>

								<a target="_blank" href="http://www.cwcsbd.org/" class="navigation">
									<i class="pi pi-globe" style="font-size: 1.2rem"></i>
								</a>
							</div>
						</div>

						<div class="text-white text-center mt-8">
							<p class="text-lg footNav uppercase">Head Office</p>
							<div class="flex flex-col items-center text-sm">
								<div class="flex items-center gap-2 footNav">
									<i class="pi pi-phone" style="font-size: .8rem"></i>
									<a class="hover:text-primary" href="tel:01759005423">+88 01759005423</a>
								</div>

								<div class="flex items-center gap-2">
									<i class="pi pi-home" style="font-size: .8rem"></i>
									<p class="">92, New Eskaton Road, Dhaka – 1000</p>
								</div>
							</div>

							<p class="text-lg mt-8 uppercase footNav">Branch Office</p>
							<div class="flex flex-col items-center text-sm">
								<div class="flex items-center gap-2 footNav">
									<i class="pi pi-phone" style="font-size: .8rem"></i>
									<a class="hover:text-primary" href="tel:01743388080">+88 01743388080</a>
								</div>

								<div class="flex justify-center">
									<div>
										<p><i class="pi pi-home pr-2" style="font-size: 0.8rem"></i>Polashpol, Bou-Bazar, Satkhira Sadar, Satkhira</p>
									</div>
								</div>
							</div>
						</div>

						<div class="text-white mt-8">
							<p class="text-lg footNav uppercase">Opening Hours</p>
							<div class="flex flex-col text-sm">
								<p class="footNav">Saturday - Thursday: 10.00 AM - 06.00 PM</p>
								<p class="">Friday: Closed</p>
							</div>
						</div>

						<div class="text-white text-center mt-8">
							<p class="text-lg font-normal footNav uppercase">Contact Us</p>
							<div class="flex flex-col items-center text-sm">
								<div class="flex justify-center footNav">
									<p><i class="pi pi-phone pr-2" style="font-size: 0.8rem"></i>+88 02 222221126</p>
								</div>
								<div class="flex justify-center footNav">
									<p><i class="pi pi-envelope pr-2" style="font-size: 0.8rem"></i>cwcs.bd@gmail.com</p>
								</div>
								<div class="flex justify-center">
									<div>
										<p><i class="pi pi-home pr-2" style="font-size: 0.8rem"></i>92, New Eskaton Road Dhaka - 1217 Bangladesh</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex justify-center pb-4">
					<p class="text-white text-sm sm:text-base">
						© 2022 Developed by <a class="tag-color" href="https://www.facebook.com/catchbitflex" target="_blank" >BitFlex </a> | <a class="tag-color" href="https://www.facebook.com/catchbangladesh" target="_blank" >Catch Bangladesh</a>
					</p>
				</div>
			</div>
		</div>

		<!-- Web Footer Style -->
		<div class="hidden lg:flex flex-col items-center footerImage" :style="{backgroundImage:`url(${host + homeData})`}">
			<div class="footerStyle w-full">
				<div class="w-full text-white bg-color h-full px-6 xl:px-16">
					<div class="flex flex-col justify-center items-center footer-height">
						<div class="container">
							<div class="flex flex-col justify-center">
								<div class="lg:flex flex-row justify-between text-left p-5 xl:p-0">
									<div>
										<div>
											<router-link to="/">
												<img :src="logo" class="w-24 h-32"/>
											</router-link>
										</div>
										<div class="flex gap-5 pt-5">
											<a target="_blank" href="https://www.facebook.com/people/Centre-for-Women-and-Children-Studies-CWCS/100083564160799/" class="navigation">
											<i class="pi pi-facebook" style="font-size: 1.4rem"></i>
											</a>

											<a target="_blank" href="https://www.linkedin.com/in/cwcs/" class="navigation">
											<i class="pi pi-linkedin" style="font-size: 1.4rem"></i>
											</a>
											
											<a target="_blank" href="https://twitter.com/CWCS_BD?fbclid=IwAR0-9xvXSEeT5fN0vLI7C43n5ZVd60gofCCadlkHqJywMCBIL_qeeUr4dxI" class="navigation">
											<i class="pi pi-twitter" style="font-size: 1.4rem"></i>
											</a>

											<a target="_blank" href="http://www.cwcsbd.org/" class="navigation">
											<i class="pi pi-globe" style="font-size: 1.4rem"></i>
											</a>
										</div>
									</div>
									
									<div>
										<p class="text-lg font-semibold mb-8 uppercase">Head Office</p>
										<div class="flex flex-col">
											<div class="flex items-center gap-2 footNav ">
												<i class="pi pi-home" style="font-size: 1rem"></i>
												<p class="w-full lg:w-72">92, New Eskaton Road, Dhaka – 1000</p>
											</div>

											<div class="flex items-center gap-2">
												<i class="pi pi-phone" style="font-size: 1rem"></i>
												<a class="hover:text-primary" href="tel:01759005423">+88 01759005423</a>
											</div>
										</div>
									</div>

									<div>
										<p class="text-lg font-semibold mb-8 uppercase">Branch Office</p>
										<div class="flex flex-col">
											<div class="flex items-center gap-2 footNav">
												<i class="pi pi-home h-full flex items-start" style="font-size: 1rem"></i>
												<p class="w-full lg:w-72">Polashpol, Bou-Bazar, Satkhira Sadar, Satkhira</p>
											</div>

											<div class="flex items-center gap-2">
												<i class="pi pi-phone" style="font-size: 1rem"></i>
												<a class="hover:text-primary" href="tel:01743388080">+88 01743388080</a>
											</div>
										</div>
									</div>

									<div>
										<p class="text-lg font-semibold mb-8 uppercase">Contact Person</p>
										<div class="flex flex-col">
											<div class="flex items-center gap-2 footNav">
												<i class="pi pi-user" style="font-size: 1rem"></i>
												<p class="">Prof. Ishrat Shamim (President)</p>
											</div>

											<div class="flex items-center gap-2 footNav">
												<i class="pi pi-phone" style="font-size: 1rem"></i>
												<a class="hover:text-primary" href="tel:01753166718">+88 01753166718</a>
											</div>
											<div class="flex items-center gap-2 footNav">
												<i class="pi pi-envelope" style="font-size: 1rem"></i>
												<a class="hover:text-primary" href="mailto:catch123@gmail.com">cwcs.bd@gmail.com</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="flex justify-center pb-4">
                        <p class="text-white text-sm sm:text-base">
                            © 2022 Developed by <a class="tag-color" href="https://www.facebook.com/catchbitflex" target="_blank" >BitFlex </a> | <a class="tag-color" href="https://www.facebook.com/catchbangladesh" target="_blank" >Catch Bangladesh</a>
                        </p>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InputText from "primevue/inputtext";
import logo from "../../assets/logo/main_logo.png";
import { mapState } from 'vuex';

export default {
	data() {
		return {
			logo: logo,
			host:"https://cwcsapi.smicee.com"
		};
	},

	components: {
		InputText
	},

	computed:{
		...mapState({
			homeData: state => state.homeApies.homeDatas.footer_image,
		})
	},

	mounted(){
		this.$store.dispatch('homeApies/getHomeData')
	},
};
</script>

<style scoped>
	.footNav {
		@apply mb-2;
	}

	.footerStyle {
		background-size: cover;
		height: 55vh;
		background-repeat: no-repeat;
	}

	.footerImage{
		background-size: cover;
  		background-repeat: no-repeat;
	}

	.bg-color {
		background-color: #000000bd;
	}

	.footerStyleMobile {
		background-size: cover;
		background-repeat: no-repeat;
	}
	.navigation{
		@apply hover:text-primary
	}

	.footer-height {
    height: calc(100% - 40px);
}

.tag-color {
    @apply text-white hover:text-primary font-semibold;
}
</style>
