<template>
    <div class="flex items-center justify-center BannerStyle" :style="{backgroundImage:`url(${host + experienceData.mid_layer_image})`}">
        <div class="bg-color w-full">
            <div class="w-full h-full flex items-center justify-center">
                <div class="container sm:px-8 lg:px-12 xl:px-16">
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4" data-aos="zoom-in">
                        <div class="flex flex-col items-center w-full py-5 lg:py-8" v-if="experienceData.experience_data">
                            <div>
                                <i class="pi text-white" :class="experienceData.experience_data.year.icon" id="iconStyle"></i>
                            </div>
                            <div class="flex justify-center text-left w-full">
                                <div>
                                    <p class="py-2 lg:py-4 sm:text-xl text-white text-center">{{experienceData.experience_data.year.title}}</p>
                                    <p class="text-xl sm:text-3xl font-semibold text-white text-center">{{experienceData.experience_data.year.value}}</p>
                                </div>
                            </div>   
                        </div> 

                        <div class="flex flex-col items-center w-full py-5 lg:py-8" v-if="experienceData.experience_data">
                            <div>
                                <i class="pi text-white" :class="experienceData.experience_data.concern.icon" id="iconStyle"></i>
                            </div>
                            <div class="flex justify-center text-left w-full">
                                <div>
                                    <div>
                                        <p class="py-2 lg:py-4 sm:text-xl text-white text-center">{{experienceData.experience_data.concern.title}}</p>
                                        <p class="text-xl sm:text-3xl font-semibold text-white text-center">{{experienceData.experience_data.concern.value}}</p>
                                    </div>
                                </div>
                            </div>   
                        </div> 

                        <div class="flex flex-col items-center w-full py-5 lg:py-8" v-if="experienceData.experience_data">
                            <div>
                                <i class="pi text-white" :class="experienceData.experience_data.client.icon" id="iconStyle"></i>
                            </div>
                            <div class="flex justify-center text-left w-full">
                                <div>
                                    <p class="py-2 lg:py-4 sm:text-xl text-white text-center">{{experienceData.experience_data.client.title}}</p>
                                    <p class="text-xl sm:text-3xl font-semibold text-white text-center">{{experienceData.experience_data.client.value}}</p>
                                </div>
                            </div>   
                        </div> 

                        <div class="flex flex-col items-center w-full py-5 lg:py-8" v-if="experienceData.experience_data">
                            <div>
                                <i class="pi text-white" :class="experienceData.experience_data.projects.icon" id="iconStyle"></i>
                            </div>
                            <div class="flex justify-center text-left w-full">
                                <div>
                                    <p class="py-2 lg:py-4 sm:text-xl text-white text-center">{{experienceData.experience_data.projects.title}}</p>
                                    <p class="text-xl sm:text-3xl font-semibold text-white text-center">{{experienceData.experience_data.projects.value}}</p>
                                </div>
                            </div>   
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import {PrimeIcons} from 'primevue/api';
import AOS from 'aos'
import 'aos/dist/aos.css'

    export default {
        data(){
            return{
                host:"https://cwcsapi.smicee.com"
            }
        },

        components:{
            PrimeIcons
        },

        computed: {
            ...mapState({
                experienceData: state => state.homeApies.homeDatas,
            })
        },

        mounted(){
            this.$store.dispatch('homeApies/getHomeData')
        },

        created() {
            AOS.init({
            duration: 1000,
            })
        }
    }
</script>
<style scoped>
    .BannerStyle{
        width: 100%;
        background-size: cover;
        height: 65vh;
        background-repeat: no-repeat;
    }

    .bg-color {
        background-color: #1f0b0b7e;
        height: 100%;
        width: 100%;
    }
    .underline-text {
        height: 3px;
        @apply block w-0 rounded-lg;
        transition: all 300ms ease;
    }

    .basicData:hover .underline-text {
        width: 100%;
        /* margin-left: 7px; */
    }
    #iconStyle{
        font-size: 3rem
    }

    @media screen and (max-width: 640px) {
        #iconStyle{
            font-size: 3rem
        }

        .BannerStyle{
            width: 100%;
            background-size: cover;
            height: 100vh;
            background-repeat: no-repeat;
        }
    }
</style>