<template>
    <div class="flex flex-col filterBar">
        <div class="filter-cross">
            <div>
                <i @click="$emit('sideBarclosed')" class="pi pi-times py-2.5 px-3" style="font-size: 1.2rem"></i>
            </div>
        </div>
        <div class="w-full">
            <div class="flex-col text-left p-4">
                <p class="font-semibold">Research And Publications</p>
                <div>
                    <p class="hover:text-primary hover:font-semibold py-2 w-full">
                        Consultancy on Women & Children Issues
                    </p>
                    <p class="hover:text-primary hover:font-semibold py-2 w-full">
                        Research Studies
                    </p>
                    <p class="hover:text-primary hover:font-semibold py-2 w-full">
                        Training Manual & Facilitators Handbooks
                    </p>
                    <p class="hover:text-primary hover:font-semibold py-2 w-full">
                        Seminar Proceedings
                    </p>
                    <p class="hover:text-primary hover:font-semibold py-2 w-full">
                        BCC Materials
                    </p>
                </div>
            </div>      
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    methods: {
        showDropdown() {
            this.toggleIcon = !this.toggleIcon
            this.displayDropdown = !this.displayDropdown
        },
    },

    computed:{
        ...mapState({
            projects: (state) => state.projectsData.projects,
        })
    }
}
</script>

<style scoped>

.filterBar {
    z-index: 999;
    width: 90vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @apply shadow-lg bg-white;
}

.filter-cross { 
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-start;
    padding-right: 4%;
    @apply border-b border-gray-300;
}
</style>