import Vue from 'vue'
import Vuex from 'vuex'
import aboutUs from './modules/aboutUs'
import areaOfWorkData from './modules/areaOfWorkData'
import campaigns from './modules/campaigns'
import galleryData from './modules/galleryData'
import homeApies from './modules/homeApies'
import projectsData from './modules/projectsData'
import contact from './modules/contact'
import review from './modules/clientReview'
import research from './modules/researchAndPublications'
import donor from './modules/donars'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    aboutUs,
    galleryData,
    areaOfWorkData,
    projectsData,
    campaigns,
    homeApies,
    contact,
    review,
    donor,
    research
  }
})
