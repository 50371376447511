<template>
	<div class="flex flex-col justify-center items-center pt-20 lg:pt-40 pb-12 lg:pb-20 bg-pink-200"> 
		<div class="container px-4 sm:px-8 lg:px-12 xl:px-16">
			<div class="flex flex-col lg:flex-row items-start">
				<div class="w-full lg:w-2/4" data-aos="fade-right">
					<div class="text-left">
						<p class="text-lg xl:text-2xl text-left p-4 border-l-4 border-primary">
							{{ projectDetails.title }}
						</p>
						<p class="text-left text-justify text-gray-600 py-5" v-html="projectDetails.details">
						</p>
					</div>
				</div>
				<div class="w-full lg:w-2/4 lg:pl-16" data-aos="fade-left">
					<img :src="host + projectDetails.image" alt="" class="w-full h-60 lg:h-80 rounded-md" />
				</div>
			</div>
		</div>
		<div class="container flex flex-col items-center" v-if="projectGallery.length > 0">
			<div class="lg:imageStyle mt-12 lg:mt-20 sm:px-8 lg:px-12 xl:px-16">
				<div class="flex flex-col w-full">
					<p class="text-lg lg:text-2xl text-black uppercase">Project Gallery</p>
					<i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
				</div>
				<div class="mt-2">
					<Carousel
					:value="projectGallery"
					:numVisible="3"
					:numScroll="1"
					:responsiveOptions="responsiveOptions"
					>
						<template #item="slotProps">
							<div class="px-2 lg:px-3" data-aos="zoom-in">
								<div class="flex justify-center items-center">
									<img
										class="w-full h-64 rounded-md"
										:src="host + slotProps.data.image"
										alt="gallery image"
									/>
								</div>
							</div>
						</template>
					</Carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Carousel from "primevue/carousel";
import { mapState } from "vuex";
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
	props: ["slug"],
	
	data() {
		return {
			project_slug:null,
			campaign_id:null,
			responsiveOptions: [
				{
					breakpoint: "1024px",
					numVisible: 2,
					numScroll: 2,
				},
				{
					breakpoint: "600px",
					numVisible: 1,
					numScroll: 1,
				},
			],
			host: "https://cwcsapi.smicee.com",
			projectDetails: {},
			projectGallery: []
		};
	},

	created() {
		AOS.init({
			duration: 1000,
		})
	},

	watch:{
		projectDetail(newValue, oldValue){
			this.projectDetails = this.projectDetail
		},

		gallery(newValue, oldValue){
			this.projectGallery = this.gallery
		}
	},

	computed: {
		...mapState({
			projectDetail: (state) => state.projectsData.projectDetails,
			gallery: (state) => state.galleryData.galleryByProject,
		}),
	},

	components: {
		Carousel
	},
	
	mounted() {
		this.$store.dispatch("projectsData/getProjectDetails", this.slug);
		this.$store.dispatch("galleryData/getGalleryListProject", this.slug);
	},

	methods: {
		
	}
};
</script>

<style scoped></style>
