import donors1 from '../../assets/donors/l-1.jpg'
import donors2 from '../../assets/donors/l-2.jpg'
import donors3 from '../../assets/donors/l-3.jpg'
import donors4 from '../../assets/donors/l-4.png'
import donors5 from '../../assets/donors/l-5.png'
import donors6 from '../../assets/donors/l-6.png'
import donors7 from '../../assets/donors/l-7.png'
import donors8 from '../../assets/donors/l-8.png'
import donors9 from '../../assets/donors/l-9.png'
import donors10 from '../../assets/donors/l-10.png'
import donors11 from '../../assets/donors/l-11.jpg'
import donors12 from '../../assets/donors/l-12.png'
import donors13 from '../../assets/donors/l-13.png'
import donors14 from '../../assets/donors/l-14.png'
import donors15 from '../../assets/donors/l-15.png'
import donors16 from '../../assets/donors/l-16.png'
import donors17 from '../../assets/donors/l-17.png'
const state={
    donors:[
        {
            id:1,
            image: donors1,
        },
        {
            id:2,
            image: donors2,
        },
        {
            id:3,
            image: donors3,
        },
        {
            id:4,
            image: donors4,
        },
        {
            id:5,
            image: donors5,
        },
        {
            id:6,
            image: donors6,
        },
        {
            id:7,
            image: donors7,
        },
        {
            id:8,
            image: donors8,
        },
        {
            id:9,
            image: donors9,
        },
        {
            id:10,
            image: donors10,
        },
        {
            id:11,
            image: donors11,
        },
        {
            id:12,
            image: donors12,
        },
        {
            id:13,
            image: donors13,
        },
        {
            id:14,
            image: donors14,
        },
        {
            id:15,
            image: donors15,
        },
        {
            id:16,
            image: donors16,
        },
        {
            id:17,
            image: donors17,
        },
    ]
}
const getters={}
const mutations={}
const actions={}
export default{
    namespaced:true,
    state,
    getters,mutations,actions
}