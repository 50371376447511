<template>
    <div class="bannerStyle flex items-center justify-center" :style="{backgroundImage:`url(${host + homeData.mid_banner_image})`}">
        <div class="bg-color flex items-center justify-center">
            <div class="container px-8 lg:px-12 xl:px-16">
                <div class="h-full flex flex-col md:flex-row gap-8 md:gap-10 lg:gap-16">
                    <div class="flex flex-col items-start justify-center w-full  md:w-1/2 lg:pr-8 md:pb-6 lg:pb-0" data-aos="flip-up">
                        <p class="text-lg lg:text-3xl text-left font-semibold text-white uppercase">Mission </p>
                        <p class="py-4 text-white text-left">The mission is to develop pro-women and child-friendly policies and strategies in Bangladesh and act as an advocacy group to lobby with the policy planners and decision-makers for its implementation in a meaningful way.</p>
                    </div>
                    <div class="flex flex-col items-start justify-center w-full md:w-1/2 lg:pr-8 md:pb-6 lg:pb-0" data-aos="flip-up">
                        <p class="text-lg lg:text-3xl text-left font-semibold text-white uppercase">Vision </p>
                        <p class="py-4 text-white text-left">The vision of the Centre for Women and Children Studies (CWCS) is the inclusion of women and children in policy agenda to ensure equality in socioeconomic, legal and political spheres leading to distributive justice.
                        </p>
                    </div>  
                </div>
                <div class="mt-8">
                    <button @click="toWhoAreWe" class="buttonStyle border-primary bg-primary text-white">Know More</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AOS from 'aos'
import { mapState } from 'vuex';
import 'aos/dist/aos.css'

export default {
    data() {
        return {
            host:"https://cwcsapi.smicee.com"
        }
    },

    computed:{
        ...mapState({
            homeData: state => state.homeApies.homeDatas,
        })
    },

    mounted(){
        this.$store.dispatch('homeApies/getHomeData')
    },

    created() {
        AOS.init({
            duration: 1000,
        })
    },

    methods:{
            toWhoAreWe() {
                this.$router.push("/who-are-we");
            },
        },

}
</script>

<style scoped>
    .bannerStyle{
        background-size: cover;
        width: 100%;
        height: 65vh;
        background-repeat: no-repeat;
    }
    .bg-color {
        background-color: #1f0b0b7e;
        height: 100%;
        width: 100%;
    }

    .buttonStyle {
        @apply py-2 md:py-3 px-8 md:px-12 border-2 rounded-lg font-semibold;
    }

    @media screen and (max-width: 768px) {
        .bannerStyle {
            height: 70vh;
        }
    }

    @media screen and (max-width: 640px) {
        .bannerStyle {
            height: 80vh;
        }
    }
</style>