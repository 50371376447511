<template>
    <div>
        <Header></Header>
        <Donate></Donate>
        <Footer></Footer>
    </div>
</template>
<script>
import Donate from '@/components/Donate/Donate.vue';
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';

    export default{
    components: { Donate, Header,Footer }
}
</script>