<template>
	<div class="bg-pink-200 example-box flex justify-center">
		<div class="background-shapes"></div>
		<div class="container flex flex-col items-center pt-20 lg:pt-40 lg:px-12 xl:px-16 pb-12 lg:pb-24">
			<div class="flex flex-col lg:flex-row px-4 lg:px-0 w-full"> 
				<div class="text-left text-justify flex flex-col w-full lg:w-1/2 lg:mr-8" data-aos="fade-right">
					<p
					class="text-lg lg:text-3xl text-black font-semibold pl-5 border-l-4 border-primary mb-6 lg:mb-10 uppercase"
					>
						Area Of Work
					</p>
					<p class="text-gray-600">
						It was 2017, and we wanted to make apps. To introduce our first app
						to market, we made an approach to few restaurants for marketing
						unlike the world had seen. When those ideas got more attention than
						the app did, we realized we might be onto something. And as much as
						the company evolved with the changing landscape, the original
						intention hasn’t changed: we want people to learn new ideas to
						remember them, and to do something good with what they learn.
					</p>
				</div>
				<div class="flex justify-center w-full lg:w-1/2 lg:ml-8 mt-6 lg:mt-0" data-aos="fade-left">
					<img class="w-full h-52 lg:h-80 rounded-md" :src="image" alt="" />
				</div>
			</div>

			<div class="imageSizeForMobile2 mt-12 lg:mt-24">
				<div v-if="areaOfWorkData.length > 0"
				class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 px-4 lg:px-0"
				>
					<div
						v-for="(item, index) in areaOfWorkData"
						:key="index"
					>
						<router-link :to="{ path: '/area-of-work/' + item.slug }">
							<div class="bg-gray-100 text-left rounded-md shadow hover:text-primary" data-aos="zoom-in">
								<div class="geeks rounded-t-lg">
									<img
										class="imageSizeForMobile1 w-full h-52 sm:h-56 xl:h-60 2xl:h-64 rounded-t-md hover:opacity-75 object-cover bg-cover"
										:src="host + item.image"
										alt=""
									/>
								</div>
								<p class="text-black hover:text-primary text-lg lg:text-lg text-left px-3 pt-3">
									{{ item.title }}
								</p>
								<div class="px-3 py-2 text-gray-600 text-left">
                                    <div v-if="item.details.length < 60">{{ item.details }}</div>
                                    <div v-else>
                                        <p>{{ item.details.substring(0, 60) + "..." }}</p>
                                    </div>
                                </div>
							</div>
						</router-link>
					</div>
				</div>
				<div v-else class="pt-8">
                <div class="loader"></div>
                <div class="pt-8 text-gray-600">Something wrong. Please wait....</div>
            </div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
import image from "../../assets/area-of-work.jpeg";
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
	data() {
		return {
			image: image,
			host: "https://cwcsapi.smicee.com",
		};
	},

	computed: {
		...mapState({
			areaOfWorkData: (state) => state.areaOfWorkData.areaOfWork,
		}),
	},

	created() {
		AOS.init({
			duration: 1000,
		})
	},

	mounted() {
		this.$store.dispatch("areaOfWorkData/getAreaOfWork");
	},
};
</script>

<style scoped>
	.seeButton {
		@apply mt-3 text-primary font-semibold text-base hover:underline;
	}

	.geeks {
		overflow: hidden;
		margin: 0 auto;
	}

	.geeks img {
		transition: 1.2s all ease-in-out;
	}

	.geeks:hover img {
		transform: scale(1.2);
	}

    .example-box {
        /* background-image: linear-gradient(to bottom right, #e5e7eb, #e5e7eb); */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        background-size: cover;
        color: white;
        font-family: sans-serif;
        font-weight: 200;
        z-index: 1;
    }

    .example-box * {
        z-index: 2;
    }

    .background-shapes {
		content: "";
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		width: 100%;
		height: 5076px;
		background-size: 100%;
		animation: 120s infiniteScroll linear infinite;
		background-repeat-x: repeat;
		background-image: url(https://cdn2.hubspot.net/hubfs/53/Pricing%202017%20Assets/marketing/Header_Circles-1.svg);
    }

    @-webkit-keyframes infiniteScroll {
		0% {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}

		100% {
			-webkit-transform: translate3d(0, -1692px, 0);
			transform: translate3d(0, -1692px, 0);
		}
    }

    @keyframes infiniteScroll {
		0% {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}

		100% {
			-webkit-transform: translate3d(0, -1692px, 0);
			transform: translate3d(0, -1692px, 0);
		}
    }

	/* custom media query */
	@media screen and (min-width: 360px) and (max-width: 640px){
			.imageSizeForMobile1 {
				@apply h-60
			}
		}

	@media screen and (min-width:410px) and (max-width: 530px) {
		.imageSizeForMobile2 {
			@apply px-12
		}
	}

	@media screen and (min-width:530px) and (max-width: 640px) {
		.imageSizeForMobile2 {
			@apply px-20
		}
	}
</style>
