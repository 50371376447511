<template>
    <div class="flex justify-center bg-primary py-2">
        <div class="container flex justify-between items-center">
            <div class="flex gap-5">
                <div class="flex items-center gap-2">
                    <i class="pi pi-envelope" style="font-size: .8rem"></i>
                    <p class="">mail@catchbangladesh.com</p>
                </div>
                <div class="flex items-center gap-2">
                    <i class="pi pi-phone" style="font-size: .8rem"></i>
                    <p>+880 171 844 468</p>
                </div>
            </div>
            <div class="flex gap-5">
                <i class="pi pi-facebook" style="font-size: 1.2rem"></i>
                <i class="pi pi-linkedin" style="font-size: 1.2rem"></i>
                <i class="pi pi-twitter" style="font-size: 1.2rem"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import {PrimeIcons} from 'primevue/api';
    export default{
        components:{
            PrimeIcons
        }
    }
</script>
<style scoped>

</style>