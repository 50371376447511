<template>
    <div>
        <Header></Header>
        <WorkingAreas></WorkingAreas>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import WorkingAreas from '@/components/WorkingAreas/WorkingAreas.vue';
    export default{
        components: { Header, Footer,WorkingAreas}
    }
</script>

<style scoped>
</style>