import axios from 'axios'
import V1API from '../../../apiPath/apiPath.js'
const state={
    galleryData:[],
    galleryByProject: []
}

const getters={}

const mutations={
    SET_GALLERY (state,items){
        state.galleryData = items
    },

    SET_GALLERY_PROJECT (state,items){
        state.galleryByProject = items
    },
}

const actions={
    getGallery({commit}){
        axios.get(V1API.get_gallery)
        .then(result =>{
            let items = result.data.data
            // console.log(items)
            commit('SET_GALLERY', items)
        })
        .catch(error =>{
            console.log(error)
        })
    },

    async getGalleryListProject({commit}, payload){
        let response = await axios.get(V1API.get_gallery_by_project + payload)
         .then(result => {
             let items = result.data.data
             // console.log(items)
             commit('SET_GALLERY_PROJECT', items)
             return result
         })
         .catch(error =>{
             console.log(error)
         })
 
         return response
     },
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}