<template>
    <div>
        <Header></Header>
        <WhoAreWe></WhoAreWe>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import WhoAreWe from '@/components/WhoAreWe/WhoAreWe.vue';
    export default{
        components: { Header, Footer, WhoAreWe}
    }
</script>

<style scoped>
</style>