import axios from "axios"
import V1API from "../../../apiPath/apiPath.js"
const state={
    message: {}
}
const getters={}
const mutations={
    SET_MESSAGE (state, item) {
        state.message = item
    },
}
const actions={
    async sendMessage({ commit }, payload) {

        let response = await axios.post(V1API.send_message, payload)
        .then(result => {
            let item = result.data
            // console.log('message -->', item)
            commit('SET_MESSAGE', item)
            return result
        })
        return response
    },
}
export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}