<template>
  <div>
    <Header></Header>
    <Banner></Banner>
    <AreaOfWork></AreaOfWork>
    <MissionAndVision></MissionAndVision>
    <OngoingProjects></OngoingProjects>
    <ResearchAndPublications></ResearchAndPublications>
    <BasicDate></BasicDate>
    <ClientReview></ClientReview>
    <ContactUs></ContactUs>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/Header.vue";
import Banner from "@/components/Home/Banner.vue";
import BasicDate from "@/components/Home/BasicDate.vue";
import ContactUs from "@/components/Home/ContactUs.vue";
import Gallery from "@/components/Home/Gallery.vue";
import OngoingProjects from "@/components/Home/OngoingProjects.vue";
import TopNav from "@/components/TopNav/TopNav.vue";
import AreaOfWork from "../../components/Home/AreaOfWork.vue";
import MissionAndVision from "@/components/Home/MissionAndVision.vue";
import ResearchAndPublications from "@/components/Home/ResearchAndPublications.vue";
import ClientReview from "@/components/Home/ClientReview.vue";

export default {
  components: {
    TopNav,
    Header,
    Banner,
    BasicDate,
    ContactUs,
    Footer,
    OngoingProjects,
    AreaOfWork,
    Gallery,
    ResearchAndPublications,
    ClientReview,
    MissionAndVision
  },
};
</script>

<style scoped>
/* .navStyle{
        top: 10;
    } */
</style>
