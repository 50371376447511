<template>
    <div>
        <Header></Header>
        <ProjectDetails :slug="getProjectSlug()"></ProjectDetails>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import ProjectDetails from '@/components/ProjectsDetails/ProjectsDetails.vue';

export default {
    components: { Header, Footer, ProjectDetails },
    
    methods:{
        getProjectSlug(){
            return this.$route.params.id
        },
    }
}
</script>
<style scoped>
</style>