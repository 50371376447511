<template>
  <div id="app">
    <Header></Header>
    <router-view />
  </div>
</template>

<script>
  import Header from './components/Header/Header.vue';

  export default {
    components:{
      Header
    }
  }
</script>

<style>
#app {
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  @apply bg-bg-color
}
.imageStyleMobile{
  width: 100%;
  height: 220px;
}
.animate-charcter{
    text-transform: uppercase;
    background-image: linear-gradient(
        -225deg,
        #0f0f0f 0%,
        #7C0046 29%,
        #F26522 67%,
        #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
}

    @keyframes textclip {
    to {
        background-position: 200% center;
    }
    }

@media screen and (min-width: 1536px) {
  .container {
    max-width: 1280px !important;
  }
}

.loader {
  margin: auto;
  border: 15px solid #EAF0F6;
  border-radius: 50%;
  border-top: 15px solid;
  width: 150px;
  height: 150px;
  animation: spinner 4s linear infinite;
  @apply border-t-primary
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
