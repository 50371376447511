<template>
	<div class="flex justify-center navStyle shadow bg-pink-400 top-0" @mouseleave="hideDropdown">
		<div class="w-full flex justify-between py-1 px-4 lg:hidden">
			<div>
				<router-link to="/"><img class="h-10 w-full" :src="logo"/></router-link>
			</div>
			<div class="flex justify-center items-center">
				<div>
					<i
						@click="toggleNav"
						class="text-white pi pi-bars cursor-pointer p-2 text-primary"
						style="font-size: 1.3rem"
					></i>
				</div>
				<Navbar v-if="navToggled" @navClosed="closeNav" />
			</div>
		</div>
		<div class="container hidden lg:flex justify-between items-center p-5 sm:px-8 lg:px-0 xl:px-8">
			<div class="flex items-center w-12 h-12">
				<router-link to="/" class=""><img :src="logo" /></router-link>
			</div>
			<div class="flex items-center h-full">
				<router-link to="/" class="flex items-center navigation hover:text-secondary">Home</router-link>
				<router-link to="/who-are-we" class="flex items-center navigation hover:text-secondary">Who Are We</router-link>
				<div class="navigation hover:text-secondary flex items-center cursor-pointer" @mouseover="showDropdown">
					<router-link to="/area-of-work">Area Of Work</router-link>
					<i
						v-if="toggleIcon"
						class="pi pi-angle-up ml-1 text-sm"
						style="font-size: 1.2rem"
					></i>
					<i v-else class="pi pi-angle-down ml-1 text-sm" style="font-size: 1.2rem"></i>
				</div>
				<div v-if="displayDropdown" class="dropdown z-50 ml-52 bg-pink-100 shadow bg-white p-2 rounded-md">
					<div class="w-full flex text-left">
						<div class="w-full">
							<div class="">
								<div v-for="(aow, index) in areaOfWork" :key="index">
									<a class="" @click="aowDetailsPage(aow.slug)">
										<p class="cursor-pointer py-3 border-b border-gray-200 px-4 text-sm hover:bg-primary hover:text-white">{{ aow.title }}</p>
									</a>
								</div>
							</div>
						</div>         
					</div>
				</div>
				<router-link to="/research-and-publications" class="flex items-center navigation hover:text-secondary">Research & Publications</router-link>
				<router-link to="/ongoing-projects" class="flex items-center navigation hover:text-secondary">Ongoing Projects</router-link>
				<router-link to="/working-areas" class="flex items-center navigation hover:text-secondary">Working Areas</router-link>
				<router-link to="/gallery" class="flex items-center navigation hover:text-secondary">Gallery</router-link>
				<router-link to="/donate" class="flex items-center navigation hover:text-secondary">Donors</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import logo from "../../assets/logo/main_logo.png";
import Navbar from "../Header/Nav.vue";

export default {
	components: {
		Navbar,
	},

	data() {
		return {
			logo: logo,
			displayDropdown: false,
			toggleIcon: false,
			navToggled: false,
		};
	},

	methods: {
		showDropdown() {
			this.toggleIcon = true;
			this.displayDropdown = true;
		},

		hideDropdown() {
			this.toggleIcon = false;
			this.displayDropdown = false;
		},

		toggleNav: function () {
			this.navToggled = !this.navToggled;
		},

		closeNav: function () {
			this.navToggled = false;
		},

		aowDetailsPage(slug) {
			this.$router.push({name: 'areaofWorkDetails', params: { id: slug }})
			this.$store.dispatch("areaOfWorkData/getAOWDetails", slug)
			this.$store.dispatch("areaOfWorkData/getAOWTitle", slug)
		}
	},

	computed: {
		...mapState({
			areaOfWork: (state) => state.areaOfWorkData.areaOfWork,
		}),
	},

	mounted(){
        this.$store.dispatch('areaOfWorkData/getAreaOfWork')
    }
};
</script>

<style scoped>
.navStyle {
	position: fixed;
	width: 100vw;
	z-index: 200;
}
.navigation {
  	@apply text-sm px-4 h-full capitalize xl:uppercase text-white;
}
/* .navigation {
  	@apply px-4 h-full text-white;
} */
.buttonStyle {
  	@apply ml-5 px-6 h-10 text-white rounded-lg font-semibold uppercase;
}

.dropdown {
	position: absolute;
	top: 75px;
	list-style-type: none;
}
</style>
