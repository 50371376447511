<template>
	<div class="flex flex-col items-center justify-center pt-12 lg:pt-16 w-full">
		<div class="bannerStyle w-full">
			<div class="bg-primary w-full h-full flex items-center justify-center px-4">
				<div class="container flex sm:px-8 lg:px-12 xl:px-16">
					<p
						class="text-xl lg:text-5xl text-white font-medium p-5 border-l-4 border-secondary"
					>
						{{ AOWtitle.title }}
					</p>
				</div>
			</div>
		</div>
		<div class="bg-pink-200 w-full flex justify-center">
			<div class="container py-12 px-4 sm:px-8 lg:px-12 xl:px-16 lg:py-20">
				<div class="flex flex-col w-full">
				<div class="pb-8 text-gray-600 text-left text-justify">
					<div v-html="AOWtitle.details"></div>
				</div>
				<div class="imageSizeForMobile2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
					<div
						v-for="(item, index) in projects"
						:key="index"
					>
						<router-link :to="{ path: '/project/' + item.slug }">
							<div class="bg-white text-left rounded-md shadow transition ease-in-out delay-90 hover:-translate-y-1 hover:scale-101" data-aos="slide-up">
								<img
									:src="host + item.image"
									alt=""
									class="imageSizeForMobile1 w-full h-52 sm:h-56 xl:h-60 2xl:h-64 rounded-t-md hover:opacity-75 object-cover bg-cover"
								/>
								<div class="p-4">
									<p class="lg:text-lg mb-2">{{ item.title.substring(0, 90)}}</p>
									<div v-if="item.details.length < 80">
										<p v-html="item.details"></p>
									</div>
									<div v-else>
										<p class="text-sm lg:text-base text-gray-600" v-html=" item.details.substring(0, 80)"></p>
										<button @click="" class="seeButton">Read more</button>
									</div>
								</div>
							</div>
						</router-link>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
	props: ['slug'],

	data() {
		return {
			host: "https://cwcsapi.smicee.com",
			AOWtitle:{},
			projects:[]
		};
	},

	computed: {
		...mapState({
			project: (state) => state.areaOfWorkData.AOWProjects,
			title: (state) => state.areaOfWorkData.AOWtitle,
		}),
	},

	created() {
		this.$store.dispatch("areaOfWorkData/getAOWDetails", this.slug)
		this.$store.dispatch("areaOfWorkData/getAOWTitle", this.slug)
		
		AOS.init({
			duration: 1000,
		})
	},

	watch: {
		project(newValue, oldValue) {
			this.projects = this.project
		},

		title(newValue, oldValue) {
			this.AOWtitle = this.title
		}
	}
};
</script>

<style scoped>
.seeButton {
  	@apply mt-3 text-primary font-semibold text-base hover:underline;
}

.bannerStyle {
	/* background-image: url("../../assets/banner.jpg"); */
	/* background-color: #c21066; */
	background-size: cover;
	height: 30vh;
	background-repeat: no-repeat;
}

/* .bg-color {
  	background-color: #000000bd;
} */

@media screen and (min-width: 360px) and (max-width: 640px){
        .imageSizeForMobile1 {
            @apply h-60
        }
    }

@media screen and (min-width:410px) and (max-width: 530px) {
    .imageSizeForMobile2 {
        @apply px-12
    }
}

@media screen and (min-width:530px) and (max-width: 640px) {
    .imageSizeForMobile2 {
        @apply px-20
    }
}
</style>
