<template>
    <div class="example-box flex flex-col w-full items-center bg-pink-200 pb-5 lg:pb-10">
        <div class="background-shapes"></div>
        <div class="container lg:px-12 xl:px-16">
            <div class="flex flex-col w-full pt-12 lg:pt-20">
                <p class="text-lg lg:text-3xl text-black font-semibold uppercase">Area Of Works</p>
                <i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
                <div class="w-full pb-6 flex justify-center text-center">
                    <p class="w-96 text-sm text-gray-500 px-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta nobis accusamus ex pariatur error</p>
                </div>
            </div>
            <div v-if="areaOfWork.length > 0">
                <div class="AOWimageSizeForMobile2">
                    <Carousel :value="areaOfWork" :numVisible="3" :numScroll="1" :responsiveOptions="responsiveOptions" :showIndicators="false" :showNavigators="false" :showThumbnails="false">
                        <template #item="slotProps">
                            <router-link :to="{path:'/area-of-work/'+slotProps.data.slug}">
                                <div class="">
                                    <div class="imageSizeForMobile3 bg-white md:mx-3 rounded-md" data-aos="zoom-in">
                                        <div class="geeks rounded-t-md">
                                            <img :src="host + slotProps.data.image" alt="Geeks Image" class="AOWimageSizeForMobile1 w-full h-48 md:h-60 lg:h-56 xl:h-64 rounded-t-md object-cover bg-cover"/>
                                        </div>
                                        <div class="bg-gray-100 rounded-b-md">
                                            <p class="text-left text-lg lg:text-xl text-black hover:text-primary px-3 pt-3">{{slotProps.data.title}}</p>
                                            <div class="px-3 py-2 text-gray-600 text-left">
                                                <div v-if="slotProps.data.details.length < 60">{{ slotProps.data.details }}</div>
                                                <div v-else>
                                                    <p>{{ slotProps.data.details.substring(0, 60) + "..." }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </template>
                    </Carousel>
                </div>
            </div>
            <div v-else class="py-8">
                <div class="loader"></div>
                <div class="pt-8 text-gray-600">Something wrong. Please wait....</div>
            </div>
        </div>
    </div>
</template>
<script>
    import Carousel from 'primevue/carousel';
    import {PrimeIcons} from 'primevue/api';
    import { mapState } from 'vuex';
    import AOS from 'aos'
    import 'aos/dist/aos.css'

    export default {
        data() {
            return {
                responsiveOptions: [
                    {
                        breakpoint: '1024px',
                        numVisible: 2,
                        numScroll: 2
                    },
                    {
                        breakpoint: '800px',
                        numVisible: 2,
                        numScroll: 2
                    },
                    {
                        breakpoint: '640px',
                        numVisible: 1,
                        numScroll: 1
                    }
                ],
                host:"https://cwcsapi.smicee.com"
            }
        },
  
        components: {
            Carousel,
            PrimeIcons
        },

        computed: {
           ...mapState({
                areaOfWork: state => state.areaOfWorkData.areaOfWork,
                // datas: state => state.homeBasicData.datas,
           })
        },
        mounted(){
            this.$store.dispatch('areaOfWorkData/getAreaOfWork')
        },
        created() {
            AOS.init({
            duration: 1000,
            })
        }
    }
</script>
<style scoped>
    .geeks {
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
    }
      
    .geeks img {
        transition: 1.2s all ease-in-out;
        @apply rounded-t-lg
    }

    .geeks:hover img {
        transform: scale(1.2);
    }

    .example-box {
        /* background-image: linear-gradient(to bottom right, #e5e7eb, #e5e7eb); */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        background-size: cover;
        color: white;
        font-family: sans-serif;
        font-weight: 200;
        z-index: 1;
        }

    .example-box * {
        z-index: 2;
        }

    .background-shapes {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 5076px;
    background-size: 100%;
    animation: 120s infiniteScroll linear infinite;
    background-repeat-x: repeat;
    background-image: url(https://cdn2.hubspot.net/hubfs/53/Pricing%202017%20Assets/marketing/Header_Circles-1.svg);
    }

    @-webkit-keyframes infiniteScroll {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(0, -1692px, 0);
        transform: translate3d(0, -1692px, 0);
    }
    }

    @keyframes infiniteScroll {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    100% {
        -webkit-transform: translate3d(0, -1692px, 0);
        transform: translate3d(0, -1692px, 0);
    }
    }

    @media screen and (min-width: 390px) and (max-width: 640px){
        .AOWimageSizeForMobile1 {
            @apply h-64
        }
    }

    @media screen and (min-width:530px) and (max-width: 640px) {
        .AOWimageSizeForMobile2 {
            @apply px-12
        }
    }

    @media screen and (min-width:641px) and (max-width: 768px) {
        .imageSizeForMobile3 {
            @apply mx-3
        }
    }
</style>