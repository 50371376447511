import axios from 'axios'
import V1API from '../../../apiPath/apiPath.js'
const state ={
    projects:[],
    ongoingProject:[],
    projectDetails:{}
}
const getters={}
const mutations={
    SET_PROJECTS (state,items){
        state.projects = items
    },

    SET_ONGOING_PROJECT (state,items){
        state.ongoingProject = items
    },

    SET_PROJECT_DETAILS (state,items){
        state.projectDetails = items
    }
}
const actions={
    getProjects({commit}){
        axios.get(V1API.get_projects)
        .then(result =>{
            let items = result.data.data
            // console.log('projects',items)
            commit('SET_PROJECTS', items)
        })
        .catch(error =>{
            console.log(error)
        })
    },

    getOngoingProjects({commit}){
        axios.get(V1API.get_ongoing_Project)
        .then(result =>{
            let items = result.data.data
            // console.log('projects',items)
            commit('SET_ONGOING_PROJECT', items)
        })
        .catch(error =>{
            console.log(error)
        })
    },

    async getProjectDetails({commit}, payload){
        let response = await axios.get(V1API.get_project_title + payload)
        .then(result =>{
            let items = result.data.data
            // console.log('project Details',items)
            commit('SET_PROJECT_DETAILS', items)
            return result
        })
        .catch(error =>{
            console.log(error)
        })
        return response
    },
}

export default {
    namespaced:true,
    state,
    getters,mutations,actions
}