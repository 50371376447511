<template>
    <div>
        <Header></Header>
        <Gallery></Gallery>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import Gallery from '@/components/Gallery/Gallery.vue'
    export default{
    components: { Gallery, Header, Footer }
}
</script>