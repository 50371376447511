import axios from 'axios';
import V1API from "../../../apiPath/apiPath.js"

const state = {
    research: [],
}

const getters = {}

const mutations = {
    GET_RESEARCH (state, items) {
        state.research = items
    }
}

const actions = {

    // Get all research 
    async get_research ({ commit }) {
        let response = await axios.get(V1API.get_research).then(result => {
            let items = result.data.data
            commit('GET_RESEARCH', items) 
        })
        return response
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}