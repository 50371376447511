<template>
  <div>
    <!-- <Header></Header> -->
    <AreaOfWorkDetails :slug="getAOWslug()"/>
    <Footer></Footer>
  </div>
</template>
<script>
import AreaOfWorkDetails from "@/components/AreaOfWorkDetails/AreaOfWorkDetails.vue";
import Footer from "@/components/Footer/Footer.vue";
// import Header from "@/components/Header/Header.vue";
export default {
  props:['id'],
  components: { Footer, 
    // Header, 
    AreaOfWorkDetails },
  methods: {
    getAOWslug() {
      return this.$route.params.id;
    },
  },
};
</script>
