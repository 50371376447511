import axios from 'axios'
import V1API from '../../../apiPath/apiPath.js'
const state={
    areaOfWork:[],
    AOWProjects:[],
    AOWtitle:{}
}
const getters={}
const mutations={
    SET_AOW(state,items){
        state.areaOfWork = items
    },

    SET_AOW_DETAILS(state,items){
        state.AOWProjects = items
    },

    SET_AOW_TITLE(state,items){
        state.AOWtitle = items
    }
}
const actions={
    async getAreaOfWork({commit}){
        let response = await axios.get(V1API.get_area_of_work)
        .then(result => {
            let items = result.data.data
            // console.log(items)
            commit('SET_AOW', items)
            return result
        })
        .catch(error =>{
            console.log(error)
        })
        return response
    },

    async getAOWDetails({commit}, payload){
        let response = await axios.get(V1API.get_area_of_work_details + payload)
        .then(result =>{
            let items = result.data.data
            // console.log('projects',items)
            commit('SET_AOW_DETAILS', items)
            return result
        })
        .catch(error =>{
            console.log(error)
        })
        return response
    },

    async getAOWTitle({commit}, payload){
        let response = await axios.get(V1API.get_AOW_title + payload)
        .then(result =>{
            let items = result.data.data
            // console.log('projects',items)
            commit('SET_AOW_TITLE', items)
            return result
        })
        .catch(error =>{
            console.log(error)
        })
        return response
    },
}
export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}