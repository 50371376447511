<template>
    <div class="bg-pink-200 flex flex-col items-center">
        <Toast :breakpoints="{'640px': {width: '60%', right: '0'}}" />
            <div class="container">
                <div class="flex flex-col gap-4 justify-center md:flex-row xl:flex-row items-center w-full xl:px-20 py-12 p-4 lg:py-20">
                    <div class="hidden md:flex flex-col text-left w-full md:w-1/2 md:mr-8" data-aos="fade-right">
                        <p class="text-lg lg:text-3xl text-left font-semibold uppercase">Get In Touch With Us!</p>
                        <p class="mt-2 text-sm text-gray-500">If you have any queries, contact us and our expert support team will answer all your questions.</p>
                        <div class="mt-6 lg:mt-10">
                            <div class="flex flex-col lg:flex-row w-full">
                                <div class="w-full lg:w-1/2 flex bg-white p-3 rounded-lg lg:mr-2">
                                    <i class="pi pi-user text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                    <p class="border-r border-gray-300"></p>
                                    <input type="text" v-model="message.name" class="bg-white pl-3 text-sm outline-none" placeholder="Your Name" name="" id="">
                                </div>
                                <div class="w-full lg:w-1/2 flex bg-white p-3 rounded-lg mt-4 lg:mt-0 lg:ml-2">
                                    <i class="pi pi-envelope text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                    <p class="border-r border-gray-300"></p>
                                    <input type="text" v-model="message.email" class="bg-white pl-3 text-sm outline-none" placeholder="Your Email" name="" id="">
                                </div>
                            </div>
                            <div class="flex bg-white p-3 rounded-lg mt-4 lg:mt-6">
                                <i class="pi pi-pencil text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                <p class="border-r border-gray-300"></p>
                                <input type="text" v-model="message.sub" class="bg-white pl-3 text-sm outline-none w-full" placeholder="Your Subject" name="" id="">
                            </div>
                            <div class="flex bg-white p-3 rounded-lg mt-4 lg:mt-6">
                                <i class="pi pi-comment text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                <p class="border-r border-gray-300 h-6"></p>
                                <textarea v-model="message.message" name="" class="bg-white pl-3 text-sm outline-none w-full" placeholder="Your message here..." id="" cols="30" rows="7"></textarea>
                            </div>
                            <button @click="sendMessage()" class="mt-6 bg-primary py-2 w-full text-white rounded-lg">Send Message</button>
                        </div>
                    </div>
                    <div class="hidden md:flex felx-col xl:ml-12 mt-5 w-full md:w-1/2">
                        <div class="flex flex-col text-left" data-aos="fade-left">
                            <div class="flex w-full items-start justify-start">
                                <p class="text-lg lg:text-2xl font-medium uppercase">Contact Us For Any Query</p>
                            </div>

                            <div class="flex mt-4 md:mt-8">
                                <div>
                                    <i class="pi pi-phone iconStyle bg-primary" style="font-size: 1.2rem"></i>
                                </div>
                                <div class="ml-8">
                                    <p class="text-2xl font-medium">Call</p>
                                    <a class="font-medium text-gray-600 mt-2 hover:text-primary" href="tel:01753166718">+88 01753166718</a>
                                </div>
                            </div>
                            <div class="flex mt-8">
                                <div>
                                    <i class="pi pi-map-marker iconStyle bg-primary" style="font-size: 1.2rem"></i>
                                </div>
                                <div class="ml-8">
                                    <p class="text-2xl font-medium">Email</p>
                                    <a class="font-medium text-gray-600 mt-2 hover:text-primary" href="mailto:cwcs.bd@gmail.com">cwcs.bd@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col md:hidden text-left w-full md:w-1/2 md:mr-8" data-aos="slide-up">
                        <p class="text-lg lg:text-3xl text-left font-semibold">Get In Touch With Us!</p>
                        <p class="mt-2 text-sm text-gray-500">If you have any queries, contact us and our expert support team will answer all your questions.</p>
                        <div class="mt-6 lg:mt-10">
                            <div class="flex flex-col lg:flex-row w-full">
                                <div class="w-full lg:w-1/2 flex bg-white p-3 rounded-lg lg:mr-2">
                                    <i class="pi pi-user text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                    <p class="border-r border-gray-300"></p>
                                    <input type="text" v-model="message.name" class="bg-white pl-3 text-sm outline-none" placeholder="Your Name" name="" id="">
                                </div>
                                <div class="w-full lg:w-1/2 flex bg-white p-3 rounded-lg mt-4 lg:mt-0 lg:ml-2">
                                    <i class="pi pi-envelope text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                    <p class="border-r border-gray-300"></p>
                                    <input type="text" v-model="message.email" class="bg-white pl-3 text-sm outline-none" placeholder="Your Email" name="" id="">
                                </div>
                            </div>
                            <div class="flex bg-white p-3 rounded-lg mt-4 lg:mt-6">
                                <i class="pi pi-pencil text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                <p class="border-r border-gray-300"></p>
                                <input type="text" v-model="message.sub" class="bg-white pl-3 text-sm outline-none w-full" placeholder="Your Subject" name="" id="">
                            </div>
                            <div class="flex bg-white p-3 rounded-lg mt-4 lg:mt-6">
                                <i class="pi pi-comment text-primary mt-1 pr-4" style="font-size:0.9rem;"></i>
                                <p class="border-r border-gray-300 h-6"></p>
                                <textarea v-model="message.message" name="" class="bg-white pl-3 text-sm outline-none w-full" placeholder="Your message here..." id="" cols="30" rows="7"></textarea>
                            </div>
                            <button @click="sendMessage()" class="mt-6 bg-primary py-2 w-full text-white rounded-lg">Send Message</button>
                        </div>
                    </div>
                    <div class="flex felx-col md:hidden xl:ml-12 mt-5 w-full md:w-1/2">
                        <div class="flex flex-col text-left" data-aos="slide-up">
                            <div class="flex w-full items-start justify-start">
                                <p class="text-lg lg:text-2xl font-medium">Contact Us For Any Query</p>
                            </div>

                            <div class="flex mt-4 md:mt-8">
                                <div>
                                    <i class="pi pi-phone iconStyle bg-primary" style="font-size: 1.2rem"></i>
                                </div>
                                <div class="ml-8">
                                    <p class="text-2xl font-medium">Call</p>
                                    <a class="font-medium text-gray-600 mt-2 hover:text-primary" href="tel:01753166718">+88 01753166718</a>
                                </div>
                            </div>
                            <div class="flex mt-8">
                                <div>
                                    <i class="pi pi-map-marker iconStyle bg-primary" style="font-size: 1.2rem"></i>
                                </div>
                                <div class="ml-8">
                                    <p class="text-2xl font-medium">Email</p>
                                    <a class="font-medium text-gray-600 mt-2 hover:text-primary" href="mailto:cwcs.bd@gmail.com">cwcs.bd@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
</template>
<script>
import Toast from "primevue/toast";
import AOS from 'aos'
import 'aos/dist/aos.css'

    export default {
        data(){
            return{
                message:{
                    name:"",
                    email:"",
                    sub:"",
                    message:""
                }
            }
        },

        components:{
            Toast
        },

        created() {
            AOS.init({
                duration: 1000,
            })
        },

        methods:{
            sendMessage(){
                this.$store.dispatch('contact/sendMessage', this.message)
                .then(response => {
                    console.log('message',response)
                    if(response.data.code == 200){
                        this.message.name = ""
                        this.message.email = ""
                        this.message.sub = ""
                        this.message.message = ""
                        this.$toast.add({severity: 'success', summary: 'Success!', detail: response.data.response, closable: false, life: 3000})
                    } else {
                        this.$toast.add({severity: 'error', summary: 'Error!', detail: response.data.response, closable: false, life: 3000})
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .iconStyle{
        @apply p-4 rounded-full text-white
    }
</style>