<template>
    <div class="bg-pink-100 flex flex-col w-full items-center pt-12 lg:pt-20 pb-5 lg:pb-10">
        <div class="container sm:px-8 lg:px-12 xl:px-16">
            <div class="flex flex-col w-full">
                <p class="text-lg lg:text-3xl text-black font-semibold uppercase">What People Say</p>
                <i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
                <div class="w-full pb-6 flex justify-center text-center">
                    <p class="w-96 text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta nobis accusamus ex pariatur error</p>
                </div>
            </div>
            <div v-if="reviewData.length > 0">
                <Carousel :value="reviewData" :responsiveOptions="responsiveOptions" :numVisible="2" 
                    :showThumbnails="false" :showIndicators="true">
                    <template #item="slotProps">
                        <div class="bg-white p-3 lg:p-5 sm:mx-4 rounded-md" data-aos="zoom-in">
                            <div class="flex gap-4 items-center text-left">
                                <div>
                                    <img class="h-20 w-20 rounded-full" :src="slotProps.data.image" alt="">
                                </div>
                                <div>
                                    <p class="text-base md:text-lg text-primary">{{ slotProps.data.name }}</p>
                                    <p class="text-xs md:text-sm text-gray-500">{{ slotProps.data.designation }}</p>
                                </div>
                            </div>
                            <div class="text-sm md:text-base text-gray-600 text-justify mt-3">
                                <div>{{ slotProps.data.review }}</div>
                            </div>
                        </div>
                    </template>
                </Carousel>
            </div>
            <div v-else class="pt-8">
                <div class="loader"></div>
                <div class="pt-8 text-gray-600">Something wrong. Please wait....</div>
            </div>
        </div>
    </div>
</template>

<script>
import Carousel from 'primevue/carousel';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { mapState } from "vuex";

    export default {
        data() {
            return {
                responsiveOptions: [
                    {
                        breakpoint: '1024px',
                        numVisible: 2
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 2
                    },
                    {
                        breakpoint: '640px',
                        numVisible: 1
                    }
                ]
            }
        },

        components: {
            Carousel
        },

        computed: {
            ...mapState({
                reviewData: (state) => state.review.reviews,
            }),
        },

        created() {
            AOS.init({
                duration: 1000,
            })
        },
    }
</script>

<style scoped>

</style>