import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import WhoAreWe from '../views/whoAreWe'
import AreaOfWork from '../views/AreaOfWork'
import Donate from '../views/Donate'
import Gallery from "../views/Gallery"
import OnGoingProjects from "../views/OnGoingProjects/"
import ResearchAndPublications from "../views/researchAndPublications"
import WorkingAreas from "../views/workingAreas"
import AreaOfWorkDetails from '../views/AreaOfWork/AreaOfWorkDetails/[id].vue'
import ProjectDetails from '../views/Campaign/Details/[id].vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/who-are-we',
    name: 'whoAreWe',
    component: WhoAreWe 
  },
  {
    path: '/area-of-work',
    name: 'areaOfWork',
    component: AreaOfWork
  },
  {
    path: '/donate',
    name: 'donate',
    component: Donate
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/area-of-work/:id',
    name: 'areaofWorkDetails',
    component: AreaOfWorkDetails
  },
  {
    path: '/project/:id',
    name: 'ProjectDetails',
    component: ProjectDetails
  },
  
  {
    path: '/ongoing-projects',
    name: 'OnGoingProjects',
    component: OnGoingProjects
  },
  {
    path: '/working-areas',
    name: 'WorkingAreas',
    component: WorkingAreas
  },
  {
    path: '/research-and-publications',
    name: 'ResearchAndPublications',
    component: ResearchAndPublications
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savedPosition){
    if(savedPosition) return savedPosition
    else return {x: 0, y: 0 }
  }
})

export default router
