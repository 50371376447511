<template>
    <div>
        <Galleria :value="bannnerData" :responsiveOptions="responsiveOptions" :numVisible="1" :circular="true" :showItemNavigators="true" :showThumbnails="false" :autoPlay="true" :transitionInterval="10000">
            <template #item="slotProps">
                 <div class="bannerStyle flex justify-center items-center" :style="{backgroundImage:`url(${host + slotProps.item.image})`}">
                    <div class="bg-color flex flex-col items-center justify-end">
                        <div class="container flex flex-col">
                            <div class="text-left w-full pb-12 lg:pb-36">
                                <p class="titleStyle text-3xl sm:text-4xl lg:text-6xl text-white p-4">{{slotProps.item.title}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Galleria>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Galleria from 'primevue/galleria';
import AOS from 'aos'
import 'aos/dist/aos.css'

    export default { 

        data(){
            return{
                host:"https://cwcsapi.smicee.com",
                responsiveOptions: [
                    {
                        breakpoint: '1024px',
                        numVisible: 1
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 1
                    },
                    {
                        breakpoint: '560px',
                        numVisible: 1
                    }
                ]
            }
        },

        components:{
            Galleria
        },

        computed:{
            ...mapState({
                bannnerData: state => state.homeApies.banner,
            })
        },

        mounted(){
            this.$store.dispatch('homeApies/getBannerData')
        },

        methods:{
            toAreOfWork() {
                this.$router.push("/area-of-work");
            },
        },

        created() {
            AOS.init({
                duration: 1000,
            })
        }
    }
</script>
<style scoped>
    .bannerStyle {
        background-size: cover;
        width: 100%;
        height: 90vh;
        background-repeat: no-repeat;
    }
    .buttonStyle {
        @apply py-2 md:py-3 px-8 md:px-12 border-2 rounded-lg font-semibold;
        transition: background 300ms ease-in-out;
    }

    .bg-color {
        height: 100%;
    }

    .titleStyle{
        font-family: niconne;
        background-color: #1a191969;
    }

    @media screen and (max-width: 1024px) {
		.bannerStyle{
			height: 90vh;
		}
	}

    @media screen and (max-width: 500px) {
		.bannerStyle{
			height: 100vh;
		}
	}
</style>