<template>
    <div>
        <p class="text-md font-semibold pt-1">Research And Publications</p>
        <div class="sidebarScroll">
            <div v-for="(item, index) in researchData" :key="index">
                <router-link :to="{ path: '/project/' + item.id }">
                    <p class="text-lg hover:text-primary mb-2">{{ item.paper_title }}</p>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"; 

    export default {
        data() {
            return {
                host: "https://cwcsapi.smicee.com",
            };
        },
        
        computed: {
			...mapState ({
				researchData: state => state.research.research,
			})
		},

		mounted() {
			this.$store.dispatch('research/get_research')
		},
    }
</script>

<style scoped>
.sidebarScroll{
      	@apply w-full h-60  overflow-y-scroll p-2;
    }

	.sidebarScroll::-webkit-scrollbar {
		width: 6px;
	}

	.sidebarScroll::-webkit-scrollbar-thumb {
		@apply bg-primary rounded-md;
	}

	.sidebarScroll::-webkit-scrollbar-track {
		@apply bg-white rounded-md;
	}
</style>