<template>
	<div class="bg-pink-200 w-full pt-12 lg:pt-20 flex flex-col items-center">
		<div class="flex flex-col w-full items-center">
			<div class="bannerStyle w-full">
				<div class="bg-primary w-full h-full flex items-center justify-center px-4">
					<div class="container flex sm:px-8 lg:px-12 xl:px-16">
						<p class="text-xl lg:text-4xl text-white font-medium p-5 border-l-4 border-secondary uppercase">
							Research & Publications
						</p> 
					</div>
				</div>
			</div>
			<div class="container py-12 px-4 sm:px-8 lg:px-12 xl:px-16 lg:py-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
				<div v-for="(item, index) in researchData" :key="index">
					<a target="_blank" rel="noopener noreferrer" :href="host + item.pdf ">
						<div class="researchHover text-left shadow" data-aos="zoom-in">
							<div class="p-2 bg-gray-100 rounded-t-md">
								<p class="lg:text-lg text-gray-600 my-2">{{ item.paper_title.substring(0, 90) }}</p>
							</div>

							<div class="h-64 w-full">
								<img
								:src="host + item.book_banner_image"
								alt=""
								class="w-full h-full rounded-b-md imageStyle"
								/>
							</div>
							<div class="hoverEffect flex justify-center items-center">
								<p class="text-white text-lg font-semibold font-medium">Author: {{item.author_name}}</p>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import { mapState } from "vuex"; 
import AOS from 'aos'
import 'aos/dist/aos.css'

    export default {
        data() {
            return {
                host: "https://cwcsapi.smicee.com",
            };
        },
        
        computed: {
			...mapState ({
				researchData: state => state.research.research,
			})
		},

		mounted() {
			this.$store.dispatch('research/get_research')
		},

        created() {
            AOS.init({
                duration: 1000,
            })
        },
    }
</script>

<style scoped>
	.hoverEffect {
		@apply hidden;

	}
	.bannerStyle {
		background-size: cover;
		height: 30vh;
		background-repeat: no-repeat;
	}
	.imageStyle{
		background-size: cover;
		background-repeat: no-repeat;
	}

	.researchHover:hover .hoverEffect{
		@apply flex flex-col h-64 -mt-64 p-4;
		background-color: #1f0218c0;
		opacity: 0.75;
	}

	@media screen and (min-width: 360px) and (max-width: 640px){
        .imageSizeForMobile1 {
            @apply h-60
        }
    }

	@media screen and (min-width:430px) and (max-width: 530px) {
		.imageSizeForMobile2 {
			@apply px-10
		}
	}
</style>