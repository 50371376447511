<template>
    <div>
        <Header></Header>
        <AreofWork></AreofWork>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '@/components/Header/Header.vue';             
import Footer from '@/components/Footer/Footer.vue';
import AreofWork from '@/components/AreaOfWork/AreofWork.vue';
    export default{
    components: { Header, Footer, AreofWork }
}
</script>