import axios from "axios"
import V1API from "../../../apiPath/apiPath.js"
const state={
    homeDatas:{},
    banner:[]
}
const getters={}
const mutations={
    GET_HOME_DATA(state,items){
        state.homeDatas = items
    },

    GET_BANNER_DATA(state,items){
        state.banner = items
    }
}
const actions={
    async getHomeData({commit}){
        let response = await axios.get(V1API.get_home_data)
        .then(res => {
            let items = res.data.data
            // console.log(items)
            commit('GET_HOME_DATA', items)
        })
        .catch(error =>{
            console.log(error)
        })
        return response
    },

    async getBannerData({commit}){
        let response = await axios.get(V1API.get_banner_data)
        .then(res => {
            let items = res.data.data
            // console.log(items)
            commit('GET_BANNER_DATA', items)
        })
        .catch(error =>{
            console.log(error)
        })
        return response
    }
}
export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}