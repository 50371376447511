<template>
  	<div class="w-full pt-12 lg:pt-20 flex flex-col items-center">
		<!-- header section -->
		<div class="bannerStyle w-full">
			<div class="bg-primary w-full h-full flex items-center justify-center px-4">
				<div class="container flex sm:px-8 lg:px-12 xl:px-16">
					<p class="text-xl lg:text-4xl text-white font-medium p-5 border-l-4 border-secondary uppercase">
						Who Are We
					</p> 
				</div>
			</div>
		</div>

		<div class="bg-pink-100 w-full flex justify-center">
			<div class="w-full container py-12 lg:py-20 px-4 sm:px-8 lg:px-12 xl:px-16 lg:px-0">
				<div class="w-full flex flex-col lg:flex-row items-center text-center w-full gap-6 lg:gap-16">
					<div class="w-full lg:w-1/2" data-aos="fade-right">
						<img :src="image" alt="" class="h-56 sm:h-60 lg:h-72 w-full rounded-md hover:opacity-75">
					</div>
					<div class="w-full lg:w-1/2 flex flex-col justify-center" data-aos="fade-left">
						<p class="text-lg lg:text-3xl text-black font-semibold text-left">
							<!-- {{aboutTitle}} -->
							<!-- Let Women's Voices Be Heard... -->
						</p>
						<p v-html="aboutDesc1" class="text-left text-justify text-gray-600"></p>
						<p v-html="aboutDesc2" class="text-left text-justify text-gray-600 pt-3"></p>
					</div>
				</div>
			</div>
		</div>

		<!-- Employee Section -->
		<div class="bg-pink-200 w-full h-full flex items-center justify-center">
			<div class="container py-12 lg:py-20 px-4 sm:px-8 lg:px-12 xl:px-16 lg:px-0">
				<div class="flex flex-col w-full">
				<p class="text-lg lg:text-3xl text-black font-semibold uppercase">MEET OUR EXECUTIVE MEMBERS</p>
				<i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
				<div class="w-full pb-6 flex justify-center text-center">
					<p class="w-96 text-sm text-gray-500">
						Help today because tomorrow you may be the one whoneeds more helping!
					</p>
				</div>
			</div>
		
			<div class="flex">
				<div class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mobileView">
					<div v-for="(about, index) in aboutData" :key="index">
						<div class="w-full flex flex-col justify-center" data-aos="flip-up">
							<img :src="about.image" alt="" class="h-64 md:h-80 bg-cover rounded-md imageSize">
							<div class="mt-2 flex flex-col">
								<p class="text-black text-lg font-medium">{{about.name}}</p>
								<p class="text-gray-500">{{about.designation}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>

		<!-- Description Section -->
		<div class="w-full flex flex-col"> 
			<div class="w-full flex justify-center bg-pink-100">
				<div class="container px-4 sm:px-8 lg:px-12 xl:px-16 lg:px-0">
					<div class="pt-12 lg:pt-20 flex flex-col w-full">
						<p class="text-lg lg:text-3xl text-black font-semibold uppercase">Legal Entity</p>
						<i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
						<div>
							<p class="text-left">
								Registered with NGO Affairs Bureau, Government of the People’s Republic of Bangladesh in
								1997; Registration Nunber -1116 and renewed for 10 years from 2017-2026.
							</p>
						</div>
					</div>
					<div class="pt-12 lg:pt-20 flex flex-col w-full">
						<p class="text-lg lg:text-3xl text-black font-semibold uppercase">Objectives</p>
						<i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
						<div class="text-left">
							<div class="flex felx-row gap-2">
								<i class="pi pi-circle-fill pt-2" style="font-size: 0.5rem;"></i>
								<p>
									Conduct research on women and children issues and dissemination of findings to
									strengthen policies and its implications.
								</p>
							</div>
							<div class="flex felx-row gap-2">
								<i class="pi pi-circle-fill pt-2" style="font-size: 0.5rem;"></i>
								<p>
									Organize workshops, seminars and dialogues among researchers, academicians, policy
									makers and development practitioners to bridge the gaps in policy and practice.
								</p>
							</div>
							<div class="flex felx-row gap-2">
								<i class="pi pi-circle-fill pt-2" style="font-size: 0.5rem;"></i>
								<p>
									Conduct training programs for socio-economic empowerment of women and children.
								</p>
							</div>
							<div class="flex felx-row gap-2">
								<i class="pi pi-circle-fill pt-2" style="font-size: 0.5rem;"></i>
								<p>
									Impart training for government officials, law enforcing agencies, local government bodies and community people for betterment of women and children.
								</p>
							</div>
							<div class="flex felx-row gap-2">
								<i class="pi pi-circle-fill pt-2" style="font-size: 0.5rem;"></i>
								<p>
									Undertake action-oriented programs to protect women and children from violence and exploitation.
								</p>
							</div>
							<div class="flex felx-row gap-2">
								<i class="pi pi-circle-fill pt-2" style="font-size: 0.5rem;"></i>
								<p>
									Emergency relief and rehabilitation work of disaster affected families, especially women and children.
								</p>
							</div>
						</div>
					</div>
					<div class="pt-12 lg:pt-20 flex flex-col w-full">
						<p class="text-lg lg:text-3xl text-black font-semibold uppercase">
							Collaboration & Coordination With GO, UN and Development Partners
						</p>
						<i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
						<div>
							<p class="text-left">
								Given the above work experiences, CWCS is member of the GO-NGO Coordination
								Committee to Combat Trafficking in Women and Children and member of Rescue Recovery
								Repatriate and Integration (RRRI) Task Force under the Ministry of Home Affairs and was
								member of the Steering Committee of the Police Reform Programme (PRP) undertaken by the
								Home Ministry and United Nations Development Fund (UNDP) from its very inception in
								2005.
							</p>
							<p class="text-left pt-3">
								CWCS has working relation by information sharing, organizing as well as attending meetings,
								workshops, seminars, conference and vis-a-versa with various government, non-government,
								international and UN agencies as well as with different development partners.
							</p>
						</div>
					</div>
					<div class="py-12 lg:py-20 flex flex-col w-full">
						<p class="text-lg lg:text-3xl text-black font-semibold uppercase">Networking</p>
						<i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
						<div>
							<p class="text-left">
								CWCS launched an informal network of NGOs called Traffic Watch Bangladesh (TWB) only
								with about 25 NGOs in 1997. Presently the number of member NGOs/CBOs has grown to
								approximately 430 within the last 16 years. CWCS is providing training and technical support
								to build their capacity and also to take anti-trafficking activities in their respective areas. A
								considerable number of these organizations are working in prevention and awareness raising
								while the others are working on issues such as protection, legal support, rescue, rehabilitation,
								re-integration etc. During the year 2007, CWCS has provided capacity building training and
								technical support to about 20 NGOs on issues related to counter trafficking in women and
								children.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
import { mapState } from "vuex";
import AOS from 'aos'
import 'aos/dist/aos.css'
import image from '../../assets/whoAreWe.jpeg'

export default {
	data(){
		return{
			image:image
		}
	},

	computed: {
		...mapState({
			aboutData: (state) => state.aboutUs.teams,  
			aboutTitle: (state) => state.aboutUs.title,  
			aboutDesc1: (state) => state.aboutUs.aboutDesc1,  
			aboutDesc2: (state) => state.aboutUs.aboutDesc2,  
		}),
	},

	created() {
		AOS.init({
			duration: 1000,
		})
	},
};
</script>

<style scoped>

	.bannerStyle {
		background-size: cover;
		height: 30vh;
		background-repeat: no-repeat;
	}

	@media screen and (min-width: 501px) and (max-width: 639px) {
		.mobileView{
			@apply grid grid-cols-2 gap-4
		}
	}
	@media screen and (min-width: 380px) and (max-width: 500px) {
		.mobileView{
			@apply px-8
		}
		.imageSize{
			@apply h-80
		}
	}



	@media screen and (max-width: 380px) {
		.imageSize{
			@apply h-72
		}
	}
	
	@media screen and (max-width: 330px) {
		.imageSize{
			@apply h-64
		}
	}
</style>
