<template>
	<div class="bg-pink-200 flex flex-col w-full items-center pt-12 lg:pt-20">
		<div class="bannerStyle w-full">
			<div class="bg-primary w-full h-full flex items-center justify-center px-4">
				<div class="container flex sm:px-8 lg:px-12 xl:px-16">
					<p class="text-xl lg:text-4xl text-white font-medium p-5 border-l-4 border-secondary uppercase">
						Gallery
					</p>
				</div>
			</div>
		</div>
		<div class="container sm:px-8 lg:px-12 xl:px-16 py-12 lg:py-20">
			<div class="text-justify px-4 lg:px-0">
				<p class="text-gray-600">
					Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil
					dolores itaque aliquid neque culpa, provident quia incidunt numquam
					harum vitae ut nam eius, corrupti quis? Atque rerum quisquam, dicta
					itaque nisi neque quidem, odio in et delectus minus? Eos quae itaque
					adipisci harum iure vel eaque! Alias quasi mollitia fugiat!
				</p>
			</div>
			<Galleria
				:value="galleryData"
				:activeIndex.sync="activeIndex"
				:numVisible="1"
				:responsiveOptions="responsiveOptions"
				:circular="true"
				:fullScreen="true"
				:showItemNavigators="true"
				containerStyle="max-width: 850px"
				:showThumbnails="false"
				:visible.sync="displayCustom"
			>
				<template #item="slotProps">
					<img :src="host + slotProps.item.image" alt="" />
				</template>
				<template #thumbnail="slotProps">
					<img
						:src="slotProps.item.thumbnailImageSrc"
						:alt="slotProps.item.alt"
						style="display: block"
					/>
				</template>
			</Galleria>
			<div
				v-if="galleryData.length > 0"
				class="imageSizeForMobile2 grid sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 xl:px-0 pt-6 lg:pt-10"
			>
				<div v-for="(items, index) of galleryData" :key="index">
					<div class="bg-white rounded-md" data-aos="zoom-in">
						<img
							:src="host + items.image"
							style="cursor: pointer"
							class="imageSizeForMobile1 w-full h-52 sm:h-56 xl:h-60 2xl:h-64 rounded-md hover:opacity-75 object-cover bg-cover"
							@click="imageClick(index)"
						/>
					</div>
				</div>
			</div>
			<div v-else class="pt-8">
                <div class="loader"></div>
                <div class="pt-8 text-gray-600">Something wrong. Please wait....</div>
            </div>
		</div>
	</div>
</template>

<script>
import Galleria from "primevue/galleria";
import { mapState } from "vuex";
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
	data() {
		return {
			activeIndex: 0,
			responsiveOptions: [
				{
					breakpoint: "1024px",
					numVisible: 5,
				},
				{
					breakpoint: "768px",
					numVisible: 3,
				},
				{
					breakpoint: "560px",
					numVisible: 1,
				},
			],
			displayCustom: false,
			host: "https://cwcsapi.smicee.com",
		};
	},

	components: {
		Galleria,
	},

	created() {
        AOS.init({
            duration: 1000,
        })
    },

	computed: {
		...mapState({
			galleryData: (state) => state.galleryData.galleryData,
		}),
	},

	mounted() {
		this.$store.dispatch("galleryData/getGallery");
	},

	methods: {
		toGallary() {
			this.$router.push("/gallery");
		},

		imageClick(index) {
			this.activeIndex = index;
			this.displayCustom = true;
		},
	},
};
</script>

<style scoped>
	.bannerStyle {
	/* background-image: url("../../assets/banner.jpg"); */
	background-size: cover;
	height: 30vh;
	background-repeat: no-repeat;
	}

	/* .bg-color {
	background-color: #000000bd;
	} */

	@media screen and (min-width: 360px) and (max-width: 640px){
        .imageSizeForMobile1 {
            @apply h-60
        }
    }

@media screen and (min-width:410px) and (max-width: 530px) {
    .imageSizeForMobile2 {
        @apply px-12
    }
}

@media screen and (min-width:530px) and (max-width: 640px) {
    .imageSizeForMobile2 {
        @apply px-20
    }
}
</style>
