<template>
	<div class="example-box">
		<div class="bg-pink-200 flex flex-col w-full items-center pb-12 lg:pb-20 pt-12 lg:pt-20">
			<div class="background-shapes"></div>
			<div class="container px-4 sm:px-8 lg:px-12 xl:px-16">
				<div class="flex flex-col w-full">
					<p class="text-lg lg:text-3xl text-black font-semibold uppercase">Ongoing Projects</p>
					<i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
					<div class="w-full pb-6 flex justify-center text-center">
						<p class="w-96 text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta nobis accusamus ex pariatur error</p>
					</div>
				</div>

				<div v-if="ongoingProjectDatas.length > 0"
				class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:mx-4 xl:mx-0 imageSizeForMobile2">
					<div
					v-for="(item, index) in ongoingProjectDatas.slice(0, 6)"
					:key="index"
					class="text-left transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-102"
					>
						<router-link :to="{ path: '/project/' + item.slug }">
							<div class="bg-gray-100 rounded-md" data-aos="slide-up">
								<img
									:src="host + item.image"
									alt=""
									class="imageSizeForMobile1 w-full h-52 sm:h-56 xl:h-60 2xl:h-64 rounded-t-md hover:opacity-75 object-cover bg-cover"
								/>
								<div class="p-4 rounded-b-md border border-t-0 border-primary border-dashed">
									<p class="lg:text-lg mb-2 text-black font-medium">{{ item.title.substring(0, 90) }}</p>
									<div class="text-sm sm:text-base text-gray-600 flex flex-col">
										<div v-if="item.details.length < 80">
											<p v-html="item.details"></p>
										</div>
										<div v-else>
											<p v-html="item.details.substring(0, 80)"></p>
											<router-link :to="{ path: '/project/' + item.slug }">
											<button @click="" class="seeButton text-primary">Read more</button>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</router-link>
					</div>
				</div>

				<div v-else class="pt-8">
					<div class="loader"></div>
					<div class="pt-8 text-gray-600">Something wrong. Please wait....</div>
				</div>
				
				<div class="mt-8">
					<button @click="toOngoingProjects" class="bg-primary buttonStyle border-primary">See More</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
import { mapState } from "vuex";

export default {
    data() {
        return {
        // currentTab: 1,
        host: "https://cwcsapi.smicee.com",
        };
    },

    computed: {
        ...mapState({
            ongoingProjectDatas: (state) => state.projectsData.ongoingProject,
        }),
    },

    mounted() {
        this.$store.dispatch("projectsData/getOngoingProjects");
    },

    methods: {
        toOngoingProjects() {
            this.$router.push("/ongoing-projects");
        },
    },

    created() {
        AOS.init({
            duration: 1000,
        })
    }

    };
</script>

<style scoped>
    .active-tab-button {
    @apply text-primary border-b-2 border-primary;
    }
    .buttonStyle {
    @apply py-2 md:py-3 px-8 md:px-12 border-2 hover:text-white rounded-lg font-semibold;
    transition: background 300ms ease-in-out;
    }
    .seeButton {
    @apply font-semibold text-base hover:underline;
    }

    .example-box {
    background-image: linear-gradient(to bottom right, #e5e7eb, #e5e7eb);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    color: white;
    font-family: sans-serif;
    font-weight: 200;
    z-index: 1;
    }

    .example-box * {
    z-index: 2;
    }

    h1 {
    font-family: Montserrat, sans-serif;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    }

    .background-shapes {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 5076px;
    background-size: 100%;
    animation: 120s infiniteScroll linear infinite;
    background-repeat-x: repeat;
    background-image: url(https://cdn2.hubspot.net/hubfs/53/Pricing%202017%20Assets/marketing/Header_Circles-1.svg);
    }

    @-webkit-keyframes infiniteScroll {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        100% {
            -webkit-transform: translate3d(0, -1692px, 0);
            transform: translate3d(0, -1692px, 0);
        }
    }

    @keyframes infiniteScroll {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        100% {
            -webkit-transform: translate3d(0, -1692px, 0);
            transform: translate3d(0, -1692px, 0);
        }
    }
    @media screen and (min-width: 360px) and (max-width: 640px){
        .imageSizeForMobile1 {
            @apply h-60
        }
    }

    @media screen and (min-width:410px) and (max-width: 530px) {
        .imageSizeForMobile2 {
            @apply px-12
        }
    }

    @media screen and (min-width:530px) and (max-width: 640px) {
        .imageSizeForMobile2 {
            @apply px-20
        }
    }

</style>
