<template>
    <div class="bg-pink-100 flex items-center justify-center py-12 lg:py-20">
        <div class="container flex flex-col px-4 sm:px-8 lg:px-12 xl:px-16">
            <div class="flex flex-col w-full">
                <p class="text-lg lg:text-3xl text-black font-semibold uppercase">Research And Publications</p>
                <i class="pi pi-minus text-primary" style="font-size:2.5rem"></i>
                <div class="w-full pb-6 flex justify-center text-center">
                    <p class="w-96 text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta nobis accusamus ex pariatur error</p>
                </div>
            </div>
            <div v-if="featuredDatas.length > 0" class="imageSizeForMobile2 grid sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:mx-4 xl:mx-0">
            <div
                v-for="(item, index) in featuredDatas.slice(0, 3)"
                :key="index"
                class="text-left text-justify"
            >
                <router-link :to="{ path: '/campaign/' + item.slug }">
                  <div class="bg-white rounded-md" data-aos="fade-right">
                      <img
                        :src="host + item.image"
                        alt=""
                        class="imageSizeForMobile1 w-full h-52 sm:h-56 xl:h-60 2xl:h-64 rounded-t-md hover:opacity-75"
                      />
                      <div class="p-4 rounded-b-md border border-t-0 border-primary border-dashed">
                        <p class="text-lg lg:text-xl text-black">{{ item.title }}</p>
                      </div>
                  </div>
                </router-link>
            </div>
            </div>
            <div v-else class="pt-8">
                <div class="loader"></div>
                <div class="pt-8 text-gray-600">Something wrong. Please wait....</div>
            </div>
            <div class="mt-8">
                <button @click="toResearchAndPublications" class="buttonStyle border-primary bg-primary text-white">Know More</button>
            </div>
        </div>
    </div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
import { mapState } from "vuex";

export default {
  data() {
    return {
      // currentTab: 1,
      host: "https://cmsapi.smicee.com",
    };
  },
  computed: {
    ...mapState({
      datas: (state) => state.projectsData.exploreNav,
      featuredDatas: (state) => state.projectsData.featured,
    }),
  },
  methods: {
    toResearchAndPublications() {
      this.$router.push("/research-and-publications");
    },
  },
  mounted() {
    this.$store.dispatch("projectsData/getProjects");
    this.$store.dispatch("projectsData/getFeatured");
  },
  created() {
    AOS.init({
      duration: 1000,
    })
  }
};
</script>

<style>
  .seeButton {
  @apply font-semibold text-base hover:underline;
}
  .buttonStyle {
          @apply py-2 md:py-3 px-8 md:px-12 border-2 rounded-lg font-semibold;
    }
/* custom media query */
  @media screen and (min-width: 360px) and (max-width: 640px){
    .imageSizeForMobile1 {
        @apply h-60
    }
  }

  @media screen and (min-width:410px) and (max-width: 530px) {
    .imageSizeForMobile2 {
        @apply px-12
    }
  }

  @media screen and (min-width:530px) and (max-width: 640px) {
    .imageSizeForMobile2 {
        @apply px-20
    }
  }
</style>