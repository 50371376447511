<template>
    <div>
        <Header></Header>
            <!-- <div class="flex flex-col items-center justify-center pt-12 lg:pt-20 w-full bg-pink-200">
                Header Section
                <div class="bg-primary bannerStyle w-full">
                    <div class="w-full h-full flex items-center justify-center">
                        <div class="container flex px-4 sm:px-8 lg:px-12 xl:px-16">
                            <p class="text-xl lg:text-4xl text-left text-white p-5 border-l-4 border-secondary uppercase">
                                Research & Publications
                            </p>
                        </div>
                    </div>
                </div>

                Mobile Sidebar & sidebar section 
                <div class="container py-12 sm:px-8 lg:px-12 xl:px-16 lg:py-20">
                    <div class="w-full flex flex-col lg:flex-row lg:gap-16">
                        <div class="flex items-start lg:hidden pb-4 md:pb-0 ">
                            <button class="flex items-center mx-4 bg-primary text-white px-6 py-2 rounded-md" @click="toggleSideBar">
                                <i class="pi pi-filter-fill pr-2"></i>
                                <p>Filter</p>
                            </button>
                            <MobileSidebar v-if="sideBarToggoled" @sideBarclosed="closedSideBar"/>
                        </div>
                        <div class="bg-pink-100 hidden lg:flex flex-col w-1/4 lg:h-96 xl:h-80 rounded-md text-left p-5 top-32 sticky">
                            <Sidebar></Sidebar>
                        </div>  
                        <div class="w-full lg:w-3/4">
                            <ResearchAndPublications></ResearchAndPublications>
                        </div> 
                    </div>
                </div>     
            </div> -->
        <ResearchAndPublications></ResearchAndPublications>
        <Footer></Footer>
    </div>
</template>
<script>
    import Header from '@/components/Header/Header.vue';
    import ResearchAndPublications from '@/components/ResearchAndPublications/ResearchAndPublications.vue';
    import Footer from '@/components/Footer/Footer.vue';
    import MobileSidebar from '@/components/ResearchAndPublications/MobileSidebar.vue';
    import Sidebar from '@/components/ResearchAndPublications/Sidebar.vue';

    export default {
        data() {
            return {
                sideBarToggoled: false,
            };
        },

        components:{
            Header,
            ResearchAndPublications,
            Footer,
            MobileSidebar,
            Sidebar
        },

        methods:{
            toggleSideBar:function(){
                this.sideBarToggoled = !this.sideBarToggoled
            },

            closedSideBar:function(){
                this.sideBarToggoled = false
            },
        }
    }
</script>

<style scoped>
    .bannerStyle { 
        /* background-image: url("../../assets/banner.jpg"); */
        background-size: cover;
        height: 30vh;
        background-repeat: no-repeat;
    }

    .bg-color {
      	background-color: #000000bd;
    }
</style>