<template>
    <div>
        <Header></Header>
        <OnGoingProjects></OnGoingProjects>
        <Footer></Footer>
    </div>
</template>
<script>
    import Header from '@/components/Header/Header.vue';
    import OnGoingProjects from '@/components/OnGoingProjects/OnGoingProjects.vue';
    import Footer from '@/components/Footer/Footer.vue';

    export default{
        components:{
        Header,
        OnGoingProjects,
        Footer
        }
    }
</script>