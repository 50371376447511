<template>
    <div class="bg-pink-200 w-full pt-12 lg:pt-20 flex flex-col items-center">
        <div class="bannerStyle w-full">
			<div class="bg-primary w-full h-full flex items-center justify-center px-4">
				<div class="container flex sm:px-8 lg:px-12 xl:px-16">
					<p class="text-xl lg:text-4xl text-white text-left font-medium p-5 border-l-4 border-secondary uppercase">
						Our Bangladesh outreach in last 25 years(1997-2022)
					</p> 
				</div>
                
			</div>
		</div>
        <div class="w-full flex items-center justify-center">
            <div class="container p-4 sm:p-8 lg:p-12 xl:p-16">
                <Lottie 
                    :options="defaultOptions" 
                    v-on:animCreated="handleAnimation"
                    class="lottie-size"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Lottie from './lottie.vue';
    import * as animationData from '../../assets/mapData.json'

    export default {
        data() {
            return {
                defaultOptions: {animationData: animationData},
                animationSpeed: 1
            }
        },

        components: {
            Lottie
        },

        methods: {
            handleAnimation: function (anim) {
                this.anim = anim;
            }
        }
    };
</script>

<style scoped>
    .bannerStyle {
        background-size: cover;
        height: 30vh;
        background-repeat: no-repeat;
    }

    .lottie-size {
        height: 100vh;
        width: 100vw;
    }
</style>