var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('div',{staticClass:"footerStyleMobile flex lg:hidden",style:({backgroundImage:`url(${_vm.host + _vm.homeData})`})},[_c('div',{staticClass:"bg-color w-full"},[_c('div',{staticClass:"flex flex-col items-center px-4 py-8"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-white"},[_c('div',{staticClass:"flex flex-col items-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"w-24",attrs:{"src":_vm.logo}})])],1),_vm._m(0)]),_vm._m(1),_vm._m(2),_vm._m(3)])]),_vm._m(4)])]),_c('div',{staticClass:"hidden lg:flex flex-col items-center footerImage",style:({backgroundImage:`url(${_vm.host + _vm.homeData})`})},[_c('div',{staticClass:"footerStyle w-full"},[_c('div',{staticClass:"w-full text-white bg-color h-full px-6 xl:px-16"},[_c('div',{staticClass:"flex flex-col justify-center items-center footer-height"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-col justify-center"},[_c('div',{staticClass:"lg:flex flex-row justify-between text-left p-5 xl:p-0"},[_c('div',[_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"w-24 h-32",attrs:{"src":_vm.logo}})])],1),_vm._m(5)]),_vm._m(6),_vm._m(7),_vm._m(8)])])])]),_vm._m(9)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center gap-5 pt-5"},[_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"https://www.facebook.com/people/Centre-for-Women-and-Children-Studies-CWCS/100083564160799/"}},[_c('i',{staticClass:"pi pi-facebook",staticStyle:{"font-size":"1.2rem"}})]),_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"https://www.linkedin.com/in/cwcs/"}},[_c('i',{staticClass:"pi pi-linkedin",staticStyle:{"font-size":"1.2rem"}})]),_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"https://twitter.com/CWCS_BD?fbclid=IwAR0-9xvXSEeT5fN0vLI7C43n5ZVd60gofCCadlkHqJywMCBIL_qeeUr4dxI"}},[_c('i',{staticClass:"pi pi-twitter",staticStyle:{"font-size":"1.2rem"}})]),_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"http://www.cwcsbd.org/"}},[_c('i',{staticClass:"pi pi-globe",staticStyle:{"font-size":"1.2rem"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-white text-center mt-8"},[_c('p',{staticClass:"text-lg footNav uppercase"},[_vm._v("Head Office")]),_c('div',{staticClass:"flex flex-col items-center text-sm"},[_c('div',{staticClass:"flex items-center gap-2 footNav"},[_c('i',{staticClass:"pi pi-phone",staticStyle:{"font-size":".8rem"}}),_c('a',{staticClass:"hover:text-primary",attrs:{"href":"tel:01759005423"}},[_vm._v("+88 01759005423")])]),_c('div',{staticClass:"flex items-center gap-2"},[_c('i',{staticClass:"pi pi-home",staticStyle:{"font-size":".8rem"}}),_c('p',{},[_vm._v("92, New Eskaton Road, Dhaka – 1000")])])]),_c('p',{staticClass:"text-lg mt-8 uppercase footNav"},[_vm._v("Branch Office")]),_c('div',{staticClass:"flex flex-col items-center text-sm"},[_c('div',{staticClass:"flex items-center gap-2 footNav"},[_c('i',{staticClass:"pi pi-phone",staticStyle:{"font-size":".8rem"}}),_c('a',{staticClass:"hover:text-primary",attrs:{"href":"tel:01743388080"}},[_vm._v("+88 01743388080")])]),_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('p',[_c('i',{staticClass:"pi pi-home pr-2",staticStyle:{"font-size":"0.8rem"}}),_vm._v("Polashpol, Bou-Bazar, Satkhira Sadar, Satkhira")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-white mt-8"},[_c('p',{staticClass:"text-lg footNav uppercase"},[_vm._v("Opening Hours")]),_c('div',{staticClass:"flex flex-col text-sm"},[_c('p',{staticClass:"footNav"},[_vm._v("Saturday - Thursday: 10.00 AM - 06.00 PM")]),_c('p',{},[_vm._v("Friday: Closed")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-white text-center mt-8"},[_c('p',{staticClass:"text-lg font-normal footNav uppercase"},[_vm._v("Contact Us")]),_c('div',{staticClass:"flex flex-col items-center text-sm"},[_c('div',{staticClass:"flex justify-center footNav"},[_c('p',[_c('i',{staticClass:"pi pi-phone pr-2",staticStyle:{"font-size":"0.8rem"}}),_vm._v("+88 02 222221126")])]),_c('div',{staticClass:"flex justify-center footNav"},[_c('p',[_c('i',{staticClass:"pi pi-envelope pr-2",staticStyle:{"font-size":"0.8rem"}}),_vm._v("cwcs.bd@gmail.com")])]),_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('p',[_c('i',{staticClass:"pi pi-home pr-2",staticStyle:{"font-size":"0.8rem"}}),_vm._v("92, New Eskaton Road Dhaka - 1217 Bangladesh")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center pb-4"},[_c('p',{staticClass:"text-white text-sm sm:text-base"},[_vm._v(" © 2022 Developed by "),_c('a',{staticClass:"tag-color",attrs:{"href":"https://www.facebook.com/catchbitflex","target":"_blank"}},[_vm._v("BitFlex ")]),_vm._v(" | "),_c('a',{staticClass:"tag-color",attrs:{"href":"https://www.facebook.com/catchbangladesh","target":"_blank"}},[_vm._v("Catch Bangladesh")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-5 pt-5"},[_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"https://www.facebook.com/people/Centre-for-Women-and-Children-Studies-CWCS/100083564160799/"}},[_c('i',{staticClass:"pi pi-facebook",staticStyle:{"font-size":"1.4rem"}})]),_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"https://www.linkedin.com/in/cwcs/"}},[_c('i',{staticClass:"pi pi-linkedin",staticStyle:{"font-size":"1.4rem"}})]),_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"https://twitter.com/CWCS_BD?fbclid=IwAR0-9xvXSEeT5fN0vLI7C43n5ZVd60gofCCadlkHqJywMCBIL_qeeUr4dxI"}},[_c('i',{staticClass:"pi pi-twitter",staticStyle:{"font-size":"1.4rem"}})]),_c('a',{staticClass:"navigation",attrs:{"target":"_blank","href":"http://www.cwcsbd.org/"}},[_c('i',{staticClass:"pi pi-globe",staticStyle:{"font-size":"1.4rem"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-lg font-semibold mb-8 uppercase"},[_vm._v("Head Office")]),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center gap-2 footNav"},[_c('i',{staticClass:"pi pi-home",staticStyle:{"font-size":"1rem"}}),_c('p',{staticClass:"w-full lg:w-72"},[_vm._v("92, New Eskaton Road, Dhaka – 1000")])]),_c('div',{staticClass:"flex items-center gap-2"},[_c('i',{staticClass:"pi pi-phone",staticStyle:{"font-size":"1rem"}}),_c('a',{staticClass:"hover:text-primary",attrs:{"href":"tel:01759005423"}},[_vm._v("+88 01759005423")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-lg font-semibold mb-8 uppercase"},[_vm._v("Branch Office")]),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center gap-2 footNav"},[_c('i',{staticClass:"pi pi-home h-full flex items-start",staticStyle:{"font-size":"1rem"}}),_c('p',{staticClass:"w-full lg:w-72"},[_vm._v("Polashpol, Bou-Bazar, Satkhira Sadar, Satkhira")])]),_c('div',{staticClass:"flex items-center gap-2"},[_c('i',{staticClass:"pi pi-phone",staticStyle:{"font-size":"1rem"}}),_c('a',{staticClass:"hover:text-primary",attrs:{"href":"tel:01743388080"}},[_vm._v("+88 01743388080")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-lg font-semibold mb-8 uppercase"},[_vm._v("Contact Person")]),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center gap-2 footNav"},[_c('i',{staticClass:"pi pi-user",staticStyle:{"font-size":"1rem"}}),_c('p',{},[_vm._v("Prof. Ishrat Shamim (President)")])]),_c('div',{staticClass:"flex items-center gap-2 footNav"},[_c('i',{staticClass:"pi pi-phone",staticStyle:{"font-size":"1rem"}}),_c('a',{staticClass:"hover:text-primary",attrs:{"href":"tel:01753166718"}},[_vm._v("+88 01753166718")])]),_c('div',{staticClass:"flex items-center gap-2 footNav"},[_c('i',{staticClass:"pi pi-envelope",staticStyle:{"font-size":"1rem"}}),_c('a',{staticClass:"hover:text-primary",attrs:{"href":"mailto:catch123@gmail.com"}},[_vm._v("cwcs.bd@gmail.com")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center pb-4"},[_c('p',{staticClass:"text-white text-sm sm:text-base"},[_vm._v(" © 2022 Developed by "),_c('a',{staticClass:"tag-color",attrs:{"href":"https://www.facebook.com/catchbitflex","target":"_blank"}},[_vm._v("BitFlex ")]),_vm._v(" | "),_c('a',{staticClass:"tag-color",attrs:{"href":"https://www.facebook.com/catchbangladesh","target":"_blank"}},[_vm._v("Catch Bangladesh")])])])
}]

export { render, staticRenderFns }