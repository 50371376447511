<template>
    <div class="flex flex-col items-center justify-center pt-12 lg:pt-20 w-full">
        <div class="bannerStyle w-full">
            <div class="bg-primary w-full h-full flex justify-center">
                <div class="container flex items-center px-4 sm:px-8 lg:px-12 xl:px-16">
                    <p class="text-xl lg:text-4xl text-white font-medium p-5 border-l-4 border-secondary uppercase">
                        Donors
                    </p>
                </div> 
            </div>
        </div>

        <!-- Donar Section -->
        <div class="w-full bg-pink-100 flex flex-col items-center justify-center">
            <div class="container text-left py-12 lg:py-20 px-4 sm:px-8 lg:px-12 xl:px-16">
                <p class="text-gray-600 text-justify">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae, nostrum corporis? Consequatur, facilis! Incidunt placeat sequi, temporibus, non harum ex, est itaque velit voluptate cum officiis aut eaque atque quod perspiciatis adipisci delectus. Corporis, obcaecati fuga totam, deleniti saepe odit voluptatum excepturi omnis expedita rerum, aut tenetur. Unde, voluptatum quam!
                </p>

                <div class="pt-6 lg:pt-10 px-4 sm:px-8 lg:px-12 xl:px-16">
                    <div class="gridView w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-5">
                        <div v-for="(donor, index) in donorsData" :key="index">
                            <div class="w-full flex justify-center" data-aos="zoom-in">
                                <img :src="donor.image" alt="" class="h-16 sm:h-20 bg-cover rounded-md"> 
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>

        <!-- Donate section -->
        <div class="w-full bg-pink-200 flex flex-col items-center justify-center">
            <div class="w-full container pt-12 lg:pt-20"> 
                <p class="text-lg lg:text-3xl text-black font-semibold uppercase">Are You Ready To Help</p>
                <div class="md:flex md:flex-col lg:flex lg:flex-row gap-16 mt-6 lg:mt-10 pb-12 lg:pb-20 sm:px-8 lg:px-12 xl:px-16">
                    <div class="w-full lg:w-1/2 px-4 lg:px-0" data-aos="fade-right">
                        <img :src="donateImage" alt="" class="w-full h-52 sm:h-46 xl:h-80 2xl:h-96 rounded-md">
                    </div>
                    <div class="w-full lg:w-1/2 flex flex-col gap-6 text-left px-4 pt-4 lg:px-0 lg:pt-0">
                        <div class="bg-white rounded-md p-5" data-aos="slide-up">
                            <p class="text-xl font-semibold mb-2">Bank ACCOUNT</p>
                            <div class="">
                                <p class="text-gray-600 text-sm pb-1">Account Name: <span class="font-semibold text-primary text-base"> World War 3</span></p>
                                <p class="text-gray-600 text-sm pb-1">Account No: <span class="font-semibold text-primary text-base"> 255-151-00-11436</span></p>
                                <p class="text-gray-600 text-sm pb-1">Account Type: <span class="text-base font-semibold text-primary"> Current Account</span></p>
                                <p class="text-gray-600 text-sm pb-1">Bank: <span class="text-base font-semibold text-primary"> NRBC</span></p>
                                <p class="text-gray-600 text-sm">Currency: <span class="text-base font-semibold text-primary"> BDT</span></p>
                            </div>
                        </div>
                        <div class="bg-white rounded-md p-5" data-aos="slide-up">
                            <p class="text-xl font-semibold my-2">MOBILE BANKING</p>
                            <div class="">
                                <p class="text-gray-600 text-sm pb-1">Account Name: <span class="text-base font-semibold text-primary"> World War 3</span></p>
                                <p class="text-gray-600 text-sm pb-1">Account: <span class="text-base font-semibold text-primary">+8801733587687</span></p>
                                <p class="text-gray-600 text-sm pb-1">Account Name: <span class="text-base font-semibold text-primary"> Donate</span></p>
                            </div>
                        </div>
                        <div class="bg-white rounded-md p-5" data-aos="slide-up">
                            <p class="text-xl font-semibold my-2">CASH</p>
                            <div class="">
                                <p class="text-gray-600 text-sm pb-1">Address: <span class="text-base font-semibold text-primary"> Uttara,sector-11</span></p>
                                <p class="text-gray-600 text-sm pb-1">Phone: <span class="text-base font-semibold text-primary"> 0289 256 556</span></p>
                                <p class="text-gray-600 text-sm pb-1">Mobile: <span class="text-base font-semibold text-primary"> +88012354698</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import donateImage from "../../assets/donate.jpg"
import AOS from 'aos'
import 'aos/dist/aos.css'
import { mapState } from "vuex";
// import image from '../../assets/footerImg.jpg'

    export default {
        data(){
            return{
                donateImage:donateImage,
            }
        },

        computed: {
            ...mapState({
                donorsData: (state) => state.donor.donors,  
            }),
        },

        created() {
            AOS.init({
                duration: 1000,
            })
        },
    }
</script>
<style scoped>
.bannerStyle {
  background-size: cover;
  height: 30vh;
  background-repeat: no-repeat;
}
</style>