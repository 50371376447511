import client1 from '../../assets/client/p-1-min.jpg'
import client2 from '../../assets/client/p-2-min.jpg'
import client3 from '../../assets/client/p-3-min.jpg'
import client4 from '../../assets/client/p-4-min.jpg'
import client5 from '../../assets/client/p-5-min.jpg'
import client6 from '../../assets/client/p-6-min.jpg'
import client7 from '../../assets/client/p-7-min.jpg'
const state={
    reviews:[
        {
            id:1,
            name:"Siam Mahmud",
            designation:'Software Engineer',
            image: client4,
            review:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable.'
        },
        {
            id:2,
            name:"Ashraful Haque",
            designation:'Programmer',
            image: client2,
            review:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable.'
        },
        {
            id:3,
            name:"Md. Mahmud",
            designation:'Actor',
            image: client3,
            review:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable.'
        },
        {
            id:4,
            name:"Samiur Rahman",
            designation:'Social Worker',
            image: client1,
            review:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable.'
        },
        {
            id:5,
            name:"Mustakim Hasan",
            designation:'CEO',
            image: client5,
            review:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable.'
        },
        {
            id:6,
            name:"Dristi",
            designation:'Software Engineer',
            image: client6,
            review:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable.'
        },
        {
            id:7,
            name:"Dristi",
            designation:'Software Engineer',
            image: client7,
            review:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which do not look even slightly believable.'
        },

    ]
}
const getters={}
const mutations={}
const actions={}
export default{
namespaced:true,
state,
getters,mutations,actions
}